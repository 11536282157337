.fmc-tabs {
  --tablist-margin: 0 auto;
  --tab-list-border-color: transparent;
  --text-size: var(--fmc-type-size);
  --text-weight: normal;
  --text-stretch: condensed;
  --text-color-hover: var(--fds-color--secondary);
  --line-height: var(--text-size);
  --tab-padding: #{fds-rem(16px) fds-rem(16px) fds-rem(32px)};
  --tab-border-color: var(--fds-color--primary);
  --tab-border-bottom-offset: #{fds-rem(16px)};
  --tab-hover-border-height: 1px;
  --tab-selected-border-height: 3px;
  --tab-selected-border-radius: var(--tab-selected-border-height);
  --tab-selected-border-display: none;
  --tab-selected-border-width: auto;
  --tab-selected-border-transform: translateX(0);
  --tab-list-border-height: 1px;
  --tab-list-border-transform: translateY(calc(var(--tab-list-border-height) - var(--tab-selected-border-height)));
  --outline-offset: #{fds-rem(8px)};

  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  margin: 0 auto;
  flex-direction: column;

  @include fds-set-brand(lincoln) {
    --tab-list-border-color: var(--fds-color--disabled2);
    --text-size: #{fds-rem(16px)};
    --text-weight: var(--fmc-type__weight-medium);
    --text-stretch: normal;
    --text-color-hover: var(--fds-color--primary);
    --tab-border-color: var(--fds-color--secondary);
    --tab-selected-border-height: 2px;
    --tab-selected-border-radius: 0;
    --tab-hover-border-height: 2px;
    --tab-border-bottom-offset: #{fds-rem(30px)};
    --tab-padding: #{fds-rem(30px) fds-rem(20px) fds-rem(50px)};
    --outline-offset: 1.5rem;
  }
}

.fmc-tabs__tablist-wrapper {
  display: flex;
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
  transform: none;
  overflow-x: auto;
  scrollbar-width: none;
}

.fmc-tabs__tablist-wrapper::-webkit-scrollbar {
  display: none;
}

.fmc-tabs__tablist {
  display: flex;
  position: relative;
  margin: var(--tablist-margin);
  width: auto;
  transition: transform var(--fmc-transition-duration) var(--fmc-transition-timing);

  @include media('>=fds-md') {
    --tablist-margin: 0 auto #{fds-rem(16px)};
    justify-content: flex-start;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: var(--tab-border-bottom-offset);
    width: 100%;
    height: var(--tab-list-border-height);
    background-color: var(--tab-list-border-color);
    transform: var(--tab-list-border-transform);
  }

  & .fmc-tabs__tab-border {
    display: var(--tab-selected-border-display);
    position: absolute;
    width: var(--tab-selected-border-width);
    height: var(--tab-selected-border-height);
    bottom: var(--tab-border-bottom-offset);
    border-radius: var(--tab-selected-border-radius);
    transform: var(--tab-selected-border-transform);
    transition: var(--fmc-transition-duration) var(--fmc-transition-timing);
    background-color: var(--tab-border-color);
  }
}

.fmc-tabs__tab {
  overflow: visible;
  position: relative;
  cursor: pointer;

  font-size: var(--text-size);
  font-stretch: var(--text-stretch);
  font-weight: var(--text-weight);
  line-height: var(--line-height);
  color: var(--fds-color--primary);
  text-align: center;
  white-space: nowrap;
  padding: var(--tab-padding);

  // remove flash on iOS mobile as accessibility indicators are customized in this CSS
  -webkit-tap-highlight-color: var(--fds-color--tertiary-0);

  & > * {
    pointer-events: none;
  }

  &:hover {
    color: var(--text-color-hover);
  }

  &::after {
    content: '';
    display: flex;
    position: absolute;
    left: 0;
    bottom: var(--tab-border-bottom-offset);
    width: 100%;
    height: var(--tab-hover-border-height);
    border-radius: var(--tab-selected-border-radius);
    transform: translateY(calc((var(--tab-selected-border-height) - var(--tab-hover-border-height)) * -0.5));
    background-color: transparent;
    transition: background-color var(--fmc-transition-duration) var(--fmc-transition-timing);
  }

  &:hover:not([aria-selected='true'])::after {
    background-color: var(--fds-color--secondary);
  }

  &:focus-visible::before {
    content: '';
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    bottom: 0;
    right: 0;
    outline: 1px solid var(--fds-color--primary);
    outline-offset: var(--outline-offset);
    transform: translateY(-50%);
  }
}

.fmc-tabs__tabpanels {
  position: relative;
  display: flex;
  margin: 1px;
}

.fmc-tabs__tabpanel {
  position: relative;
  padding-right: 0;
  display: none;

  &.fmc-tabs__tabpanel--focus {
    outline: 1px solid var(--fds-color--primary);
  }

  &.fmc-tabs__tabpanel--active {
    display: block;
  }
}
