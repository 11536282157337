// ================================
// Icons
// ================================

$fds-font--ford-icons: 'FordIcons' !default;
$fds-font--ford-icons__play-symbol: '\e900' !default;
$fds-font--ford-icons__info-filled: '\e901' !default;
$fds-font--ford-icons__chevron-filled-left: '\e902' !default;
$fds-font--ford-icons__success: '\e903' !default;
$fds-font--ford-icons__long-arrow-back: '\e904' !default;
$fds-font--ford-icons__info: '\e905' !default;
$fds-font--ford-icons__chevron-down: '\e906' !default;
$fds-font--ford-icons__chevron-up: '\e907' !default;
$fds-font--ford-icons__mask: '\e908' !default;
$fds-font--ford-icons__clear: '\e909' !default;
$fds-font--ford-icons__unmask: '\e90a' !default;
$fds-font--ford-icons__warning-outline: '\e90b' !default;
$fds-font--ford-icons__chevron-left: '\e90c' !default;
$fds-font--ford-icons__chevron-right: '\e90d' !default;
$fds-font--ford-icons__edit-outline: '\e90e' !default;
$fds-font--ford-icons__check-filled: '\e90f' !default;
$fds-font--ford-icons__car-rear-filled: '\e910' !default;
$fds-font--ford-icons__car-side-filled: '\e911' !default;
$fds-font--ford-icons__seat-filled: '\e912' !default;
$fds-font--ford-icons__search: '\e913' !default;
$fds-font--ford-icons__communication: '\e914' !default;
$fds-font--ford-icons__ellipsis: '\e915' !default;
$fds-font--ford-icons__minus: '\e916' !default;
$fds-font--ford-icons__plus: '\e917' !default;
$fds-font--ford-icons__chevron-filled-right: '\e91a' !default;
$fds-font--ford-icons__tick: '\e91d' !default;
$fds-font--ford-icons__warning-filled: '\e990' !default;
$fds-font--ford-icons__filter: '\e920' !default;
$fds-font--ford-icons__location: '\e921' !default;
$fds-font--ford-icons__circle-clock: '\e922' !default;
$fds-font--ford-icons__calendar-filled: '\e923' !default;
$fds-font--ford-icons__circle-times-filled: '\e924' !default;
$fds-font--ford-icons__delete: '\e925' !default;
$fds-font--ford-icons__disabled: '\e926' !default;
$fds-font--ford-icons__business-plan: '\e927' !default;
$fds-font--ford-icons__edit-filled: '\e928' !default;
$fds-font--ford-icons__email: '\e929' !default;
$fds-font--ford-icons__settings-cog: '\e930' !default;
$fds-font--ford-icons__exception: '\e931' !default;
$fds-font--ford-icons__notification: '\e932' !default;
$fds-font--ford-icons__open: '\e933' !default;
$fds-font--ford-icons__refresh: '\e934' !default;
$fds-font--ford-icons__settings: '\e935' !default;
$fds-font--ford-icons__file: '\e936' !default;
$fds-font--ford-icons__avatar: '\e937' !default;
$fds-font--ford-icons__document: '\e938' !default;
$fds-font--ford-icons__first-aid: '\e939' !default;
$fds-font--ford-icons__secured-lock: '\e940' !default;
