@import "../fds-system/src/scss/fds";
@import "./components/navigation.scss";
@import "./components/layout.scss";
@import "./components/cards.scss";
@import "./components/tabs.scss";
@import "./components/badge.scss";
@import "./components/table.scss";
@import "./components/typography.scss";
@import "../fds-system/src/scss/layout/margin";
@import "../fds-system/src/scss/layout/padding";
@import "./components/accordion.scss";
@import "./components/icon.scss";
@import "./components/message-center.scss";

:root {
	--fds-layout-grid-padding-lg: 60px;
	--fds-layout-grid-margin-lg: 12px;
	--fds-layout-grid-gutter-lg: 12px;
	--fds-layout-grid-padding-md: 30px;
	--fds-layout-grid-margin-md: 0px;
	--fds-layout-grid-gutter-md: 0px;
}

body {
	font-size: 16px;
	height: 100%;
	overflow-y: scroll;
	overflow-x: clip;
}

.total-max-body {
	max-width: 1680px;
	margin: auto;
	background-color: #fafafa;
}

.footer-bg-override {
	background-color: #fafafa;
}

main {
	.fds-type--body1 a,
	.fds-type--body2 a {
		color: var(--fds-color--secondary) !important;
		text-decoration: underline;
		&:hover {
			color: var(--fds-color--primary) !important;
		}
	}
}

.fds-weight--light {
	font-weight: 300 !important;
}

.fds-weight--normal {
	font-weight: 400 !important;
}

.fds-weight--medium {
	font-weight: 500 !important;
}

.fds-weight--bold {
	font-weight: 700 !important;
}

.fmc-dialog {
	z-index: 1000;
}

.fmc-tooltip.fmc-tooltip__nav .fmc-tooltip__content {
	max-width: 45rem;
}

.h-100 {
	height: 100%;
}

.h-auto {
	height: "auto";
}

.w-100 {
	width: 100%;
}

.fds-flex__flex-auto {
	flex: 1 1 auto;
}

ul li.fmc-cards__image,
ul li.fds-img {
	list-style: none;
}

.fds-img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.fds-color--primary {
	color: $fds-color--primary !important;
}

.fds-color--secondary {
	color: $fds-color--secondary !important;
}

.fds-color--white {
	color: $fds-color--white !important;
}
.fds-color--black {
	color: $fds-color--black !important;
}
.fds-color--gray1 {
	color: $fds-color--gray1 !important;
}
.fds-color--gray2 {
	color: $fds-color--gray2 !important;
}
.fds-color--gray3 {
	color: $fds-color--gray3 !important;
}
.fds-color--gray4 {
	color: $fds-color--gray4 !important;
}
.fds-color--gray5 {
	color: $fds-color--gray5 !important;
}
.fds-color--caution1 {
	color: $fds-color--caution1 !important;
}
.fds-color--error1 {
	color: $fds-color--error1 !important;
}
.fds-color--error2 {
	color: $fds-color--error2 !important;
}

.fds-color--success1 {
	color: $fds-color--success1 !important;
}

.fds-color--success2 {
	color: $fds-color--success2 !important;
}

.om-fmc-important {
	&.fds-color--white {
		color: $fds-color--white !important;
	}
	&.fds-color--black {
		color: $fds-color--black !important;
	}
	&.fds-color--gray1 {
		color: $fds-color--gray1 !important;
	}
	&.fds-color--gray2 {
		color: $fds-color--gray2 !important;
	}
	&.fds-color--gray3 {
		color: $fds-color--gray3 !important;
	}
	&.fds-color--gray4 {
		color: $fds-color--gray4 !important;
	}
	&.fds-color--gray5 {
		color: $fds-color--gray5 !important;
	}
	&.fds-color--caution1 {
		color: $fds-color--caution1 !important;
	}
	&.fds-hide {
		display: none !important;
	}
}

.om-align--end-v {
	align-self: end;
}

.om-benefit-card__card-list {
	overflow: hidden scroll;
	height: 35vw;
	max-height: 140px;
	@include media("<=fds-lg") {
		max-height: 120px;
	}
	@include media("<=fds-md") {
		max-height: 400px;
	}
}

.om-list-style--none {
	list-style: none;
}

.om-fmc-divider--none {
	border: none;
}

.om-fmc-divider--primary {
	border-color: $fds-color--primary;
}

.text-decoration--none {
	text-decoration: none;
	text-decoration-line: none !important;
}
.text-decoration--none:hover {
	text-decoration: none;
}

.text-decoration--none.fmc-breadcrumbs__link.fds-color__text--gray2 {
	text-decoration: none;
}
.text-decoration--none.fmc-breadcrumbs__link.fds-color__text--gray2:hover {
	text-decoration: none;
}

.fmc-button {
	white-space: nowrap;
}

.fmc-overflow-menu__item {
	height: unset;
	min-height: 4.8rem;
}

.fmc-input--wrapper * {
	color: $fds-color--primary;
}

.om-apexcharts {
	.apexcharts-legend {
		@extend .fds-flex__row;
		margin: 0 auto;
		max-width: fds-rem(520px);
		.apexcharts-legend-series {
			@extend .fds-flex__col-6;
		}
	}
	.om-apexcharts-annotation {
		tspan:nth-child(2) {
			@extend .fds-weight--bold;
		}
	}
}

.fmc-side-tabs__tab.fds-stretch--normal {
	@extend .fds-stretch--normal;
}

.fds-flex--column {
	display: flex;
	flex-flow: column;
}

.fmc-check:not(.om-fmc-radio-check) {
	max-width: 2rem;
}

.fmc-button--active {
	--text-decoration: underline;
}

.fmc-tooltip .fmc-tooltip__wrapper {
	z-index: 102;
}

.fds-app .fds-checkbox .fmc-type--checkbox-label {
	font-size: 1.6rem !important;
	line-height: 1.6rem !important;
}

.fds-app .fds-icon--16 {
	font-size: 1.6rem !important;
}

.fds-app .fds-checkbox .fds-checkbox__input,
.fds-app .fds-checkbox .fds-checkbox__check,
.fds-app .fds-checkbox .fds-checkbox__box {
	width: 1.6rem !important;
	height: 1.6rem !important;
}

.fds-app .fmc-accordion__button {
	margin-top: 1.6rem !important;
	margin-bottom: 1.6rem !important;
	.fmc-accordion__button-text {
		font-size: fds-rem(20px) !important;
	}
}

.fds-list-square {
	display: list-item;
	list-style-type: square;
}

.cvc-crumb {
	cursor: pointer;
}

.cvc-crumb.cvc-crumb-active {
	cursor: auto;
}

.cvc-word-wrap-break {
	overflow-wrap: break-word;
}

.cvc-accordion-panel .fmc-accordion__body {
	overflow: auto;
}

.reference-guide-panel .fmc-accordion__body {
	min-height: 510px;
}

.no-scroll-panel .fmc-accordion__body {
	overflow-y: hidden;
}

.cvc-accordion-sub-title {
	font-size: 1.8rem;
	padding: 2rem 1rem;
	padding-left: 1.8rem;
	background: #eee;
	border-bottom: 1px solid #eee;
	margin-bottom: 2rem !important;
	margin-left: -2rem !important;
	margin-top: -2rem !important;
	margin-right: -2rem !important;
}

.remove-border-top.fds-color_bg--dark.fmc-accordion--light {
	border-top: unset !important;
}
.remove-border-top .fmc-cards__footer {
	border-top: unset !important;
}

.remove-border-bottom {
	border-bottom: unset !important;
}

.accordion-panel-color-fill.fds-color_bg--dark.fmc-accordion--light > h3 > button {
	margin: 0 !important;
	padding-top: 3rem !important;
	padding-bottom: 3rem !important;
}

@media only screen and (max-width: 1024px) {
	.larger-font {
		font-size: 1.6rem !important;
	}

	.cvc-accordion-panel .fmc-accordion__body {
		max-height: fit-content;
		overflow-y: visible !important;
	}
}

.placholder-primary-color::placeholder {
	color: var(--fds-color--primary) !important;
}

.filled-gray #panel-content {
	background-color: var(--fds-color--gray1);
	border-top: 1px solid var(--fds-color--gray3);
}

.accordion-content-center #panel-content {
	text-align: -webkit-center;
}

.divider-top-and-bottom {
	border-top: 1px solid var(--fds-color--gray2);
	border-bottom: 1px solid var(--fds-color--gray2);
}

.divider-bottom-remove-last li:not(:last-child) {
	border-bottom: 1px solid var(--fds-color--gray2);
}

.x-button .fmc-dialog__close {
	font-size: 1.8rem !important;
	padding-top: 3rem;
	padding-right: 3rem;
}

.center-multiple-browsers {
	text-align: -moz-center;
	text-align: -webkit-center;
}

p.font-size--cta {
	font-size: 1.6rem;
}

button span.font-size--cta {
	font-size: 1.6rem;
}

span.font-size--cta {
	font-size: 1.6rem;
}

h6.font-size--cta {
	font-size: 2rem;
}

.dropdown-link {
	position: absolute;
	top: 13rem;
	z-index: 100;
	background-color: white;
	box-shadow: var(--fmc-elevation__box-shadow--layer1);
}

.backdrop--invisible {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 2;
	height: 100vh;
	width: 100vw;
}

.remove-top-padding-R-titles .fmc-cards__content {
	padding-top: 0.5rem;
}

#FDSCard.card-rounded {
	border-radius: 4px;
}

#FDSCard.setHeight {
	height: 80%;
	margin-top: 4rem;
}

.change-to-pointer:hover {
	cursor: pointer;
}

.larger-input .fds-text-field__input-wrapper {
	height: 45px !important;
}

.larger-input--font .fds-text-field__input {
	font-size: 1.4rem;
}
.larger-input-label--font .fds-text-field__eyebrow {
	font-size: 1.2rem !important;
}

.tooltip-content--padding.tooltip-content--padding .fmc-tooltip__body {
	padding-right: 2rem;
}

.show-all-text .tooltip__content {
	min-height: fit-content;
	min-width: fit-content;
	overflow: visible;
}

.program-banner {
	background-color: #f4f4f4;
	box-sizing: border-box;
	width: 100%;
	height: 40px;
	font-family: inherit;
	padding: 0;
}

.program-banner a {
	color: #1700f4;
	text-decoration: underline;
}

@media only screen and (max-width: 1439px) {
	.dropdown-link {
		top: 8rem;
	}
}

@media only screen and (max-width: 1024px) {
	.bbas-logo--size {
		margin-left: 6rem;
	}

	.checkbox-padding--left {
		padding-left: 0;
	}

	.search-bar--size-mobile-100 .fds-section-search__combobox-wrapper {
		padding-right: 0;
		width: 100%;
	}
}
.mecpdivider-right {
	border-right: 1px solid #979797;
}

@media only screen and (max-width: 767px) {
	.bbas-logo--size {
		margin-left: 3rem;
	}
}

@media only screen and (min-width: 1124px) {
	.checkbox-margin--left {
		margin-left: 0.5rem;
	}
}

@media only screen and (min-width: 1600px) {
	.checkbox-padding--xl {
		padding-left: 3rem;
	}
	.full-width-checkbox--label > ul {
		flex-basis: 100%;
		max-width: 100%;
	}
}
@media only screen and (min-width: 2000px) {
	.checkbox-padding--xl {
		padding-left: 4rem;
	}
}
@media only screen and (min-width: 2560px) {
	.checkbox-padding--xl {
		padding-left: 6rem;
	}
}

// Added

.footer-link-text {
	font-size: 13px !important;
	text-decoration: none !important;
}

.footer-row.footer-link-text a {
	text-decoration: none;
	-webkit-text-decoration: none !important;
}

.footer-row {
	max-height: 4rem;
	text-decoration: none !important;
	contain: content;
}

.nav-bottom-divider {
	border-bottom: 1px solid var(--fds-color--gray2);
}

.dealer-info {
	display: flex;
	flex-direction: row;
	box-sizing: content-box;
	line-height: 22px;
	min-width: 361px;
}

.dealerAlign {
	display: grid;
	justify-content: end;
}

.navDealer-info {
	font-size: 14px;
	text-align: end;
}
.navDealer-info-mobile {
	font-size: 14px;
	text-align: start;
	letter-spacing: 1px;
	line-height: 22px;
	white-space: nowrap;
}

.navDealer-container {
	min-width: 3rem;
	position: relative;
	margin-bottom: 0.5rem;
	margin-right: 1em;
}

.navDealer-avatar {
	position: absolute;
	bottom: 0;
}

.navImg {
	max-width: 295px;
}

.dashboard-title-block {
	display: flex;
	justify-content: center;
}

.dash-chart-container {
	border-radius: 20px;
	background-color: #f4f4f4;
	padding: 15px;
	contain: content;
	width: 100%;
	height: 280px;
}

.current-earnings {
	height: 165px;
	width: 98%;
	min-width: 290px;
	white-space: nowrap;
	background-color: #ffff;
	border: 2px solid #9c9c9c;
	font-size: 18px;
	text-align: left;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	padding: 15px 30px;
}
.current-earnings-mobile {
	height: 165px;
	width: 98%;
	min-width: 290px;
	white-space: nowrap;
	background-color: #ffff;
	border: 2px solid #9c9c9c;
	font-size: 18px;
	align-items: center;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	padding: 15px 30px;
}
.current-earning-text-container {
	text-align: left;
}

.current-earning-text-container p {
	font-size: 18px;
	color: #9c9c9c;
	padding: 2px 0;
}
.current-earnings p {
	font-size: 18px;
	color: #9c9c9c;
	padding: 2px 0;
}

.MAAP-Earnings-Link {
	font-size: 12px;
	text-decoration: underline;
	color: #1700f4;
}
.current-earnings-complete {
	height: 165px;
	width: 98%;
	min-width: 290px;
	white-space: nowrap;
	background-color: #ffff;
	border: 2px solid #066fef;
	font-size: 18px;
	text-align: left;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	padding: 15px 30px;
}

.current-earnings-complete p {
	font-size: 18px;
	color: black;
	padding: 2px 0;
}

.center-progress-dividers {
	border-left: 1px solid var(--fds-color--gray2);
	border-right: 1px solid var(--fds-color--gray2);
}
.progress-img {
	width: 220px;
	height: auto;
}

.normalFontStretch {
	font-stretch: normal;
}
.dealer-dash-search .fds-section-search__combobox-wrapper .fds-search__input-wrapper {
	// min-width: 600px !important;
	max-width: 100%;
}
.dealer-dash-search .fds-section-search__combobox-wrapper {
	padding-right: 0;
	background-color: white;
}

.dealer-dash-search .fds-section-search__combobox-wrapper .fds-search__combobox-wrapper {
	padding-top: 0;
}

.dashSearch {
	background-color: white;
	border: 1px solid grey;
}
.results-list-wrap {
	width: 100%;
	height: 600px;
	overflow: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.results-list-wrap {
	height: 600px;
	--scrollbar-width: 1px;
	--mask-height: 50px;
	padding-top: 10px;
	padding-bottom: var(--mask-height);
	--mask-image-content: linear-gradient(to bottom, transparent, black var(--mask-height), black calc(100% - var(--mask-height)), transparent);
	--mask-size-content: calc(100% - var(--scrollbar-width)) 100%;
	--mask-image-scrollbar: linear-gradient(black, black);
	--mask-size-scrollbar: var(--scrollbar-width) 100%;
	mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
	mask-size: var(--mask-size-content), var(--mask-size-scrollbar);
	mask-position: 0 0, 100% 0;
	mask-repeat: no-repeat, no-repeat;
}

.dealer-dash-search-results::-webkit-scrollbar {
	display: none;
}

.dealer-dash-search-results ul {
	list-style: none;
}
.dealer-dash-single-result {
	width: 100%;
}
.dealer-dash-single-result:hover {
	background-color: rgb(236, 235, 235);
}

.dealer-dash-single-result-link {
	display: block;
	padding: 2rem;
	border-bottom: 1px solid grey;
}

.result-wrapper {
	padding: 0 3rem;
}

.dealerName {
	font-weight: bold;
	padding-bottom: 0.5rem;
}

.dealerInformation {
	color: grey;
	font-weight: 100;
}

// Dashboard
.dash-top-accordion .fmc-accordion__button .fmc-accordion__button-title {
	text-align: left;
	font-size: 18px;
	width: 100%;
}

.dash-info-icon {
	max-height: 18px;
	padding: 2px;
}

.dash-status-icon {
	padding-right: 1rem;
	height: 18px;
}

.dash-panel-status,
.dash-panel-status-text {
	font-size: 14px;
	font-weight: 600;

	text-transform: uppercase;
}

.dash-panel-font-green {
	color: #008200;
}

.dash-panel-font-orange {
	color: #d62d0a;
}

.dash-panel-font-gray {
	color: #4d4d4d;
}

.mobile-dash-text {
	font-size: 16px;
	font-weight: 550;
}

//dashboard link container space
.dash-top-accordion .fmc-accordion__button .accordion-inner-link-container {
	display: flex;
	position: absolute !important;
	margin-right: 40rem !important;
	width: 165px;
}
@media (min-width: 1440px) and (max-width: 1920px) {
	.dash-top-accordion .fmc-accordion__button .accordion-inner-link-container {
		display: flex;
		position: absolute !important;
		margin-right: 40rem !important;
		width: 165px;
	}
}
@media (min-width: 1024px) and (max-width: 1440px) {
	.dash-top-accordion .fmc-accordion__button .accordion-inner-link-container {
		display: flex;
		position: absolute !important;
		margin-right: 20rem !important;
		width: 165px;
	}
}

.dash-top-accordion .fmc-accordion__button .accordion-inner-link {
	// position: absolute !important;
	// margin-right: 40rem !important;
	color: #1700f4;
	text-decoration: underline;
	text-transform: uppercase;
	text-align: start;
	font-stretch: condensed;
	font-size: 14px;
	white-space: nowrap;
	padding: 0 1.5rem;
}
.dash-top-accordion .fmc-accordion__button .accordion-inner-text {
	position: absolute !important;
	margin-right: 3.8rem !important;
	color: #4d4d4d;
	font-size: 14px;
}

.dash-top-accordion .fmc-accordion__body {
	background-color: #f4f4f4;
}

.inner-content {
	width: 100%;
}

.dash-inner-panel-title {
	font-size: 18px;
}

.panel-section {
	border-bottom: 1px solid #c3c3c3;
	padding: 20px 40px;
}

.panel-section:last-child {
	border-bottom: none;
}
.fmeQ-tooltip {
	position: absolute;
}
.fmc-tooltip.row-info-tooltip .fmc-tooltip__wrapper .fmc-tooltip__content .fmc-tooltip__header and .fmc-tooltip.fmeQ-tooltip-mobile .fmc-tooltip__wrapper .fmc-tooltip__content .fmc-tooltip__header {
	margin-bottom: 0;
}

.fmc-tooltip.row-info-tooltip .fmc-tooltip__wrapper .fmc-tooltip__content .fmc-tooltip__body {
	font-size: 18px;
	text-decoration: underline;
}

.fmc-tooltip.monthly-incentive-tooltip .fmc-tooltip__wrapper .fmc-tooltip__content .fmc-tooltip__body {
	margin-top: 5rem;
	font-size: 18px;
	color: var(--fds-color--primary);
}

.mobile-row-tooltip li {
	font-size: 18px;
}

.fmc-tooltip.fmeQ-tooltip-mobile .fmc-tooltip__wrapper .fmc-tooltip__content .fmc-tooltip__header {
	min-height: 1rem;
	margin-bottom: 2rem;
}
.fmc-tooltip.fmeQ-tooltip-mobile .fmc-tooltip__wrapper .fmc-tooltip__content .fmc-tooltip__body {
	min-height: 1rem;
	padding: 1rem;
}

.fmc-tooltip.row-info-tooltip .fmc-tooltip__wrapper .fmc-tooltip__content .fmc-tooltip__close {
	font-size: 16px;
}

.fmc-tooltip.row-info-tooltip .fmc-tooltip__wrapper .fmc-tooltip__content .fmc-tooltip__body {
	font-weight: normal;
}

.fds-section-search {
	display: flex;

	@media (min-width: map-get($fds-breakpoints, "fds-sm")) {
		flex-direction: row;
	}

	&.fds-search--focused,
	&.fds-search--active,
	&.fds-search--dirty {
		& .fds-search__input-wrapper::before {
			color: var(--fds-color--primary);
		}
	}

	&.fds-search--dirty {
		& .fds-section-search__clear-button {
			opacity: 1;
		}
	}

	& .fds-search__input-wrapper::before {
		@extend %fds-icons;

		content: $fds-font--ford-icons__search;
		margin-left: fds-rem(15px);
		width: fds-rem(16px);
		height: fds-rem(16px);
	}

	& .fds-search__input {
		padding-left: fds-rem(20px);
	}

	&.fds-color__bg--dark:not(.fds-search--focused):not(.fds-search--active):not(.fds-search--dirty) {
		& .fds-search__input-wrapper::before {
			color: var(--fds-color--white);
		}

		& .fds-section-search__clear-button::before {
			color: var(--fds-color--white);
		}
	}
}

.fds-section-search__combobox-wrapper {
	@media (min-width: map-get($fds-breakpoints, "fds-sm")) {
		padding-right: fds-rem(20px);
	}
}

.fds-section-search__clear-button {
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
	margin-right: fds-rem(15px);
	color: var(--fds-color--primary);
}

.fds-section-search__clear-button--focused::before {
	outline: 1px solid var(--fds-color--primary);
	outline-offset: fds-rem(10px);
}

.fds-section-search__submit-button-wrapper {
	position: relative;
	top: fds-rem(31px);
	z-index: 2;
	display: flex;
	align-items: flex-start;
	justify-content: center;

	& .fds-primary-button {
		@media (min-width: map-get($fds-breakpoints, "fds-sm")) {
			position: relative;
			top: fds-rem(5px);
		}
	}
}

$fds-search__option-height: fds-rem(50px);
$fds-search__option-max: 5;

.fds-search {
	display: flex;
	flex-direction: column;
	height: fds-rem(80px);
	width: 100%;
	overflow: visible;

	&.fds-color__bg--light {
		&.fds-search--focused {
			& .fds-search__focus-outline {
				outline-color: var(--fds-color--primary);
			}
		}

		& .fds-search__input-wrapper {
			border-color: var(--fds-color--gray2);
		}

		& .fds-search__label {
			color: var(--fds-color--gray3);
		}
	}

	&.fds-color__bg--dark {
		&.fds-search--focused {
			& .fds-search__focus-outline {
				outline-color: var(--fds-color--white);
			}
		}

		& .fds-search__input-wrapper {
			border-color: var(--fds-color--white);
		}

		& .fds-search__label {
			color: var(--fds-color--white);
		}

		& .fds-search__input {
			color: var(--fds-color--primary);

			&:-webkit-autofill {
				-webkit-text-fill-color: var(--fds-color--white);
			}

			&::placeholder {
				color: var(--fds-color--white);
			}
		}
	}
}

.fds-search__focus-outline {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: transparent;
}

.fds-search--focused {
	& .fds-search__focus-outline {
		outline-width: 1px;
		outline-style: solid;
		outline-offset: fds-rem(10px);
	}
}

.fds-search--focused,
.fds-search--active {
	& .fds-search__input-wrapper {
		box-shadow: inset 0 0 0 transparent, $fds-elevation__box-shadow--layer2;
		background-color: var(--fds-color--white);
		color: var(--fds-color--primary);
	}
}

.fds-search--dirty,
.fds-search--active,
.fds-search--valid {
	& .fds-search__label {
		position: relative;
		opacity: 1;
		margin-right: fds-rem(5px);
	}
}

.fds-search--dirty {
	& .fds-search__input-wrapper {
		background-color: var(--fds-color--white);
	}
}

.fds-search__list--show {
	& .fds-search__list-wrapper {
		opacity: 1;
		display: block;
		transition: opacity 0.3s ease-in-out;
	}

	& .fds-search__input-wrapper {
		border-radius: fds-rem(3px 3px 0 0);
	}
}

.fds-search--valid {
	& .fds-search__input-wrapper {
		border-color: var(--fds-color--success1);
	}

	&:not(.fds-search__password) {
		& .fds-search__message {
			color: var(--fds-color--success1);
		}
	}

	& .fds-search__label-icon {
		display: block;
	}
}

.fds-search--invalid {
	& .fds-search__message {
		opacity: 1;
		color: var(--fds-color--error1);
	}

	& .fds-search__input-wrapper {
		border-color: var(--fds-color--error1);
	}

	& .fds-search__label {
		transition-duration: 0s;
	}

	& .fds-search__label-icon {
		display: block;
	}
}

.fds-search__combobox-wrapper {
	position: relative;
	padding-top: fds-rem(31px);
}

.fds-search__describedby {
	display: none;
}

.fds-search__eyebrow {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: fds-rem(16px);
	margin-bottom: fds-rem(15px);
}

.fds-search__label-wrapper {
	display: flex;
	align-items: center;
}

.fds-search__label {
	position: absolute;
	white-space: nowrap;
	opacity: 0;
	font-family: "FordAntenna";
	font-size: fds-rem(12px);
	line-height: 1;
	letter-spacing: 1px;
	transition: opacity 0.3s ease-in-out;
}

.fds-search__input-wrapper {
	border-radius: fds-rem(3px);
	border-width: 1px;
	border-style: solid;
	background-color: transparent;
	transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
	height: fds-rem(50px);
	width: 100%;
	position: relative;
	z-index: 2;
	display: flex;
	align-items: center;
}

.fds-search__input {
	@extend %fmc-type--body1;

	padding: fds-rem(0 15px);
	width: 100%;
	height: 100%;
	background-color: transparent;
	color: var(--fds-color--primary);
	-webkit-appearance: none; /* stylelint-disable property-no-vendor-prefix */
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
	resize: none;
	border: none;

	&:-webkit-autofill {
		-webkit-text-fill-color: var(--fds-color--gray3);
	}

	&::placeholder {
		color: var(--fds-color--gray3);
		opacity: 1;
	}
}

.fds-search__message {
	opacity: 0;
	width: 100%;
	font-family: "FordAntenna";
	font-size: fds-rem(11px);
	line-height: fds-rem(20px);
	letter-spacing: 1px;
}

.fds-search__status {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	position: absolute;
	bottom: 0;
	padding-top: fds-rem(6px);
	height: fds-rem(20px);
}

.fds-search__list-wrapper {
	opacity: 0;
	display: none;
	position: relative;
	z-index: 3;
	box-shadow: inset 0 0 0 transparent, $fds-elevation__box-shadow--layer2;
}

.fds-search__list {
	width: 100%;
	max-height: calc(#{$fds-search__option-max} * #{$fds-search__option-height});
	overflow-y: scroll;
	border-radius: 0 0 fds-rem(3px) fds-rem(3px);
	background-color: var(--fds-color--white);
	color: var(--fds-color--primary);
	border: solid 1px var(--fds-color--gray2);
	border-top: none;
	list-style: none;
	padding: 0;
	resize: none;
}

.fds-search__list-option {
	@extend %fmc-type--body1;

	line-height: $fds-search__option-height;
	padding: fds-rem(0 16px);
	cursor: pointer;
}

.fds-search__list-option--focused {
	background-color: var(--fds-color--gray2);
	color: var(--fds-color--white);
	outline: none;
}

.fmc-top-banner.fmc-top-banner--persistent.dash-qualification-banner {
	max-height: 20% !important;
	--border-bottom: 1rem solid var(--alert-color) !important;
}

.country-swap-container {
	padding: 4px;
	min-width: 40px;
	height: auto;
}

.country-swap-btn {
	min-width: 40px;
	height: auto;
}

.language-toggle {
	color: var(--fds-color--primary);
}

.language-toggle:hover {
	color: var(--fds-color--secondary);
}

.dash-qualification-banner .fmc-top-banner__inner .fmc-top-banner__content .dash-temp-banner {
	display: flex;
	flex-direction: row;
}

.dash-table-container {
	width: 100%;
	padding-left: 3rem;
	padding-right: 3rem;
	min-height: 13rem;
}
.dash-table,
.landing-table,
.incentive-table,
.lvl-3-table {
	width: 100%;
	border-collapse: collapse;
}

.dash-table-head-row {
	color: var(--fds-color--primary);
	background: transparent;
}
.dash-table-head-row th:not(.fds-align--center) {
	text-align: left;
	font-weight: 500;
	padding-left: 10px;
	border-bottom: 1px solid #9c9c9c;
}

.dash-table-head-row th.fds-align--center {
	text-align: center;
	font-weight: 500;

	border-bottom: 1px solid #9c9c9c;
}

.dash-table-text {
	font-size: 15px;
	white-space: nowrap;
}

.dash-table-text-ex-test {
	font-size: 15px;
	white-space: nowrap;
	font-weight: 600;
	padding-top: 1px;
	font-stretch: extra-condensed;
}

.dash-table-spacer {
	min-width: 247px;
}

.dash-table td {
	padding: 0px 15px;
	max-width: 225px;
	word-wrap: break-word;
}

.dash-table td:first-child {
	width: 7%;
}
.dash-table td:nth-child(2) {
	width: 50%;
	display: flex;
	flex-direction: row;
}
.dash-table td:nth-child(3) {
	text-align: center;
	width: 16%;
}
.dash-table td:nth-child(4) {
	padding-left: 2em;
	width: 15%;
	border-bottom: none;
}

.landing-table td {
	padding: 0px 15px;
	max-width: 225px;
	word-wrap: break-word;
}

.landing-table td:first-child {
	width: 5%;
}
.landing-table td:nth-child(2) {
	display: flex;
	flex-direction: row;

	width: 35%;
}
.landing-table td:nth-child(3) {
	padding-left: 3em;
}
.landing-table td:nth-child(4) {
	padding-left: 2em;

	border-bottom: none;
}

.incentive-table-text {
	font-size: 1.5rem;
	line-height: 1.8rem;
}

.incentive-table td {
	padding: 15px 15px;
	max-width: 225px;
	word-wrap: break-word;
}

.incentive-table tr:not(thead) {
	padding: 1rem 0rem;
	border-bottom: 1px solid #cccccc;
}
.incentive-table tr:last-child {
	border-bottom: none;
}

.incentive-table td:first-child,
.incentive-table thead tr th:first-child {
	width: 5%;
}
.incentive-table td:nth-child(2),
.incentive-table thead tr th:nth-child(2) {
	width: 35%;
}

.lvl-3-table thead tr:last-child {
	height: 10px;
}
.lvl-3-table tr {
	vertical-align: baseline;
}

.lvl-3-table td:first-child {
	width: 75%;
}
.lvl-3-table td:last-child {
	width: 25%;
}
.lvl-3-table td:last-child,
.lvl-3-table .dash-table-head-row th:last-child {
	text-align: center;
	padding-right: 2rem;
}

.lvl-3-section-title {
	font-size: 1.8rem;
	color: #066fef;
}
.lvl-3-text {
	color: #066fef;
}
.dash-charts-row {
	width: 100%;
}

.table-status-icon {
	max-height: 16px;
	align-self: center;
}

//table tool tip

.fmc-tooltip.fmc-tooltip--opened.dash-table-tooltip .fmc-tooltip__wrapper {
	width: 40rem;
}

.fmc-tooltip.fmc-tooltip--opened.dash-table-tooltip .fmc-tooltip__wrapper .fmc-tooltip__content {
	white-space: normal !important;
	height: fit-content;
	width: 125%;
	max-width: unset;
}
.fmc-tooltip.fmc-tooltip--opened.dash-table-tooltip .fmc-tooltip__wrapper .fmc-tooltip__content ul {
	z-index: 1001;
}

.battery-chart-container {
	background-color: #f4f4f4;
	width: 100%;
	height: 333px;
	border-radius: 20px;
	padding: 30px;
	contain: content;
	margin: auto;
}

.battery-chart-container a {
	font-style: italic;
}
.battery-container {
	background-color: transparent;
	// min-width: fit-content;
	width: 90px;
	min-height: auto;
	overflow: hidden;
}
.battery-container-landing {
	background-color: transparent;
	width: auto;
	height: 115px;
	min-width: 53px;
	overflow: hidden;
	object-fit: cover;
}

.batteryImg {
	height: 150px;
}

.landingBatteryImg {
	height: 100px;
}

.completeBatteryImg,
.completeBatteryImgLanding {
	height: 100%;
}

.mobileBatteryImg {
	height: clamp(75px, 100px, 150px);
}

.battery-wrapper {
	display: flex;
	justify-content: center;
	margin-top: 1em;
}
.battery-wrapper-complete {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 2em;
}
.battery-wrapper-complete .batteryImg {
	height: 100px;
	padding-left: 2rem;
}
.battery-wrapper-complete .battery-container {
	height: 100px;
}

.battery-wrapper-complete .dash-battery-text {
	margin-top: 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #008200;
}
.dash-battery-complete {
	color: #008200;
	font-size: 3rem;
	margin-top: 1rem;
}

.dash-battery-text {
	margin-top: 30%;
	margin-left: 1rem;
	min-width: 140px;
}
.dash-battery-text span {
	display: inline-block;
}

.dash-battery-text p {
	font-size: 2.2rem;
	font-weight: 500;
}

.completion-percent-text {
	font-size: 2.5rem;
}

.hidden-qualifier-text {
	font-size: 1.7rem;
	color: #4d4d4d;
}
.hidden-qualifier-text-mobile {
	font-size: 1.7rem;
	color: #4d4d4d;
}

.qual-battery-text {
	font-size: 2rem;
	letter-spacing: 0px;
}
.qual-battery-text-mobile {
	font-size: clamp(1.5rem, 2rem, 3rem);
	letter-spacing: 0px;
}
.battery-percentage-text-landing {
	font-size: 2rem;
}

.landing-battery-text,
.landing-btn-qual {
	font-size: 1.6rem;
	line-height: 1.8rem;
}

.battery-percentage-text-mobile {
	font-size: clamp(3.5rem, 4.3rem, 5rem);
	font-weight: bold;
	margin-bottom: 0.7rem;
	line-height: 3.5rem;
}

.battery-percentage-text-mobile.hidden-qualified,
.landing-battery-text.hidden-qualified,
.battery-percentage-text-landing.hidden-qualified {
	color: #008200;
}

.battery-percent-display,
.battery-percentage-text {
	font-size: 5rem;
	font-weight: 600;
	margin-bottom: 1rem;
}

.qual-battery-text.hidden-qualified,
.battery-percentage-text.hidden-qualified {
	color: #008200;
}

.dash-lock-sections {
	width: 100%;
	height: 90%;
}
.dash-lock-sections p {
	color: #6e6e6e;
}

.dash-charts-two-container {
	background-color: #f4f4f4;
	width: 100%;
	height: 333px;
	border-radius: 20px;
	contain: content;
}

.dash-lock-divider {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.2rem;
}

.lock-divider-h {
	border-bottom: 2px solid #6e6e6e;
	min-width: 50px;
}
.lock-divider-h-complete {
	border-bottom: 2px solid #008200;
	min-width: 50px;
}

.lock-divider-v1 {
	border-right: 1px solid #6e6e6e;
	min-width: 32px;
	min-height: 75px;
	margin: 1rem 0;
}
.lock-divider-v2 {
	min-width: 32px;
	min-height: 75px;
	margin: 1rem 0;
}

#progress-bar {
	float: left;
	width: 33.33%;
}

.smaller-col {
	padding-left: 0;
	padding-right: 0;
	flex-basis: 6%;
	max-width: 6%;
	flex: 0 0 auto;
}
.dash-locked-img {
	max-height: 60px;
	padding-bottom: 1rem;
}

.dealer-disclaimer {
	font-size: 10px;
	line-height: 14px;
	color: #6e6e6e;
}

.radial-container {
	align-self: center;
	height: 100%;
}

.radial-container text {
	font-family: inherit;
	font-weight: bold;
}

.dash-container-size {
	flex-basis: 70% !important;
	max-width: 69% !important;
}

.mobile-progress-container {
	background-color: #f4f4f4;
	border-radius: 20px;
	padding: 1.5em;
	width: 100%;
	margin: auto;
	min-height: 200px;
}

.mobile-progress-heading {
	color: #066fef;
	font-size: 18px;
	font-weight: 600;
	text-align: left;
}

.currently-earning-container {
	display: flex;
	flex-direction: column;
	border: 2px solid #1700f4;
	border-radius: 20px;
	padding: 1.5em 2em;
	width: 100%;
	contain: content;
	margin: auto;
	min-height: 200px;
}

.currently-earning-container p {
	text-align: left;
}
.currently-earning-container span {
	font-weight: bold;
}
.currently-earning-header {
	font-size: 18px;
	font-weight: 600;
	text-align: center !important;
}

.bar-progress-container {
	display: flex;
	flex-direction: column;

	border: 2px solid #b1b1b1;
	overflow: clip;
	border-radius: 20px;
}

.bar-progress {
	height: 30px;
	background-color: #066fef;
}

.bar-title {
	font-weight: 500;
}
.MAAP-Earnings-Link {
	color: #1700f4 !important;
	font-size: 12px;
	text-decoration: underline;
}

.bar-tasks {
	font-size: 12px;
	color: #4d4d4d;
	padding-bottom: 0.5rem;
	line-height: normal;
}

// custom battery

// .battery-container {
//     background-color: #f4f4f4;
//     width: 90px;
//     height: 235px;
//     overflow: hidden;
//     position: relative;
// }

// .battery-top{
//     height:11px;
//     width:30px;
//     position: relative;
//     margin-left: 31px;
//     margin-bottom: -7px;
//     border-radius: 5px;
//     background-color: #066FEF
// }
// .battery-wrap{

//     border: 5px solid #066FEF;
//     position: relative;

//     transform: translateY(-50%);
//     border-radius: 10px;
//     top: 50%;
// }

// .battery-bar-container {
//     background-color: #f4f4f4;
//     width: 75px;
//     height: 220px;
//     border-radius: 10px;
//     border: 1px solid #f4f4f4;
//     }

// .battery-bar{
//     background-color: #066FEF;

//     position: absolute;
//     bottom: 1px;
//     width: 97%;
//     height: 80%;
//     //border-top: 6px solid #FFF;
//     box-sizing: border-box;
//     animation: grow 1.5s ease-out forwards;
//     transform-origin: bottom;
//     border-bottom-left-radius: 10px;
//     border-bottom-right-radius: 10px;
//     }
//     @keyframes grow{
//     from{
//         transform: scaleY(0);
//     }
// }

.completed-compliance {
	color: #6e6e6e !important;
}
.completed-compliance-radial-status {
	color: #6e6e6e !important;
	font-size: 13px;
}

.radial-container .semicircle-percent-value {
	font-family: inherit;
	font-size: 40px;
	font-weight: bold;
	color: #4d4d4d;
}

.total-max-body .fmc-accordion .fmc-accordion--light {
	--button-background-color: white;
	--button-background-color-active: white !important;
}
.total-max-body .fmc-accordion .fmc-accordion__button {
	background-color: white;
}

.total-max-body .fmc-accordion__button:hover {
	--button-background-color: white;
}

.total-max-body .fmc-accordion .fmc-accordion__panel .fmc-accordion--active,
.total-max-body .fmc-accordion .fmc-accordion__panel .fmc-accordion--expanded {
	--button-background-color: white;
}

.total-max-body .fmc-accordion .fmc-accordion__panel .fmc-accordion--expanded {
	--button-background-color: white;
}

.dashboard-link :hover {
	cursor: pointer;
}

.coming-soon-text {
	color: white;

	font-weight: 500;
	font-size: 5rem;
	line-height: initial;
}

.wholeScreen {
	min-height: 980px;
	max-width: 100vw;
	aspect-ratio: 3/2;
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 80% 50%;
	background-image: url("../images/hero-coming-soon/coming-soon_1920x980-desktop.jpg");
	background-color: #00142e;
}
// .wholeScreenV2 {
//     min-height: 980px;
//     max-width: 1680px;
//     position: relative;
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-position: 80% 50%;
//     background-image: url('../images/hero-coming-soon/coming-soon_1920x980-desktop.jpg');
//     background-color: #00142e;

// }

.coming-soon-overlay {
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	color: white;
	display: flex;
	align-items: left;
	justify-content: left;
	flex-direction: column;
}

.coming-soon-scrim {
	background: rgb(0, 20, 46);
	background: linear-gradient(90deg, rgba(0, 20, 46, 1) 0%, rgba(9, 9, 121, 0.01162471824667366) 100%);
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
}

@media screen and (max-width: 1439px) {
	.coming-soon-text {
		color: white;
		padding: 2rem 0;
		font-weight: 500;
		font-size: 4rem;
		line-height: initial;
	}

	.wholeScreen {
		min-height: 800px;

		max-width: 100vw;
		position: relative;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 80% 50%;
		background-image: url("../images/hero-coming-soon/coming-soon_1440x800-desktop.jpg");
		background-color: #00142e;
	}
}

@media screen and (max-width: 1125px) {
	.wholeScreen {
		min-height: 980px;

		max-width: 100vw;
		position: relative;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-image: url("../images/hero-coming-soon/coming-soon_1194x734-mobile.jpg");
		background-color: #00142e;
	}
}

.mobile-soon-bg {
	background-color: #00142e;
	color: white;
	display: flex;
	flex-direction: column;
	height: 100vh;
}

.coming-soon-text-mobile {
	color: white;
	padding: 2.5rem;
	font-weight: 400;
	font-size: 3.3rem;
	line-height: initial;
}

.om-fmc__navbar--mobile.opened .semi-modal {
	position: absolute;
	top: 0;
	min-width: 100vw;
	min-height: 100vh;
	background-color: rgba(0, 0, 0, 0.733);
	z-index: 99;
}

.mobile-close-position {
	position: absolute;
	right: 0;
}

.dealer-dropdown-hidden {
	display: none;
}

.open-icon-chev {
	position: relative;
	right: -15%;
	transform: rotate(180deg);
}

.closed-icon-chev {
	position: relative;
	right: -15%;
}

.dealer-secondary-info {
	padding-top: 3rem;
}
.dealer-secondary-info ul {
	list-style: none;
}

.dealer-secondary-info li {
	font-size: 14px;
	letter-spacing: 1px;
	line-height: 22px;
	color: #4d4d4d;
}
.dealer-secondary-info li span {
	font-weight: bold;
}

.nav-icon-sizing {
	display: inline-block;
	height: 2rem;
	width: 2rem;
}

.mobile-nav-link-container {
	margin-top: 3em;
}

.dash-mobile-accordion .fmc-accordion__button .fmc-accordion__button-title {
	font-size: 15px !important;
	color: var(--fds-color--primary);
	font-weight: normal;
	text-align: left;
	width: 100%;
	padding-top: 5px;
	padding-bottom: 5px;
}

.dash-mobile-accordion .fmc-accordion__button .accordion-inner-link {
	margin-right: 2rem !important;
	color: #1700f4;
	text-decoration: underline;
	text-transform: uppercase;
	text-align: start;
	font-stretch: condensed;
	font-size: 14px;
	margin-left: 3rem;
}

// .dash-mobile-accordion .dash-panel-status, .dash-mobile-accordion .accordion-inner-link {
//     padding-left: 1rem;
// }

.dash-mobile-accordion .fmc-accordion__button {
	padding-top: 5px;
}

.dash-mobile-accordion .fmc-accordion__body {
	background-color: #f4f4f4;
}

.dash-mobile-divider {
	border-top: 1px solid #c8c8c8;
	margin-top: 1em;
}

.dash-mobile-spacer {
	min-height: 4rem;
}

.non-compliant-header {
	color: #d62d0a;
	padding-bottom: 1em;
	white-space: nowrap;
}
.non-compliant-header img {
	height: 16px;
}

.compliant-header {
	color: #008200;
	padding-bottom: 1em;
	white-space: nowrap;
}
.compliant-header img {
	height: 16px;
}

.waiver-header,
.defer-header {
	color: #6d6d6d;
	padding-bottom: 1em;
	white-space: nowrap;
}

.waiver-header img {
	height: 16px;
}

.defer-header img {
	height: 16px;
}

.language-swap-container {
	padding: 4px;
	min-width: 40px;
	height: auto;
}

.language-swap-btn {
	min-width: 40px;
	height: auto;
}

.modeleresourcetabs .fmc-tabs__tablist {
	justify-content: center;
}

.modeleresourcetabs .fmc-tabs__tablist .fmc-tabs__tab[aria-selected="true"]::after {
	content: "";
	display: flex;
	position: absolute;
	left: 0;
	bottom: var(--tab-border-bottom-offset);
	width: 100%;
	height: 3px;
	border-radius: var(--tab-selected-border-radius);
	transform: translateY(calc((var(--tab-selected-border-height) - var(--tab-hover-border-height)) * -0.5));
	background-color: black;
	transition: background-color var(--fmc-transition-duration) var(--fmc-transition-timing);
}

.card-size {
	width: calc(50% - 24px);
}
@supports (display: grid) {
	.card-size {
		width: auto;
		grid-column-end: span 4;
	}
}

@media (min-width: 20em) and (max-width: 50em) {
	.card-size {
		width: calc(100% - 24px);
	}
	@supports (display: grid) {
		.card-size {
			width: auto;
			grid-column-end: span 8;
		}
	}
}

@media (min-width: 50.1em) and (max-width: 64em) {
	.card-size {
		width: calc(50% - 24px);
	}
	@supports (display: grid) {
		.card-size {
			width: auto;
			grid-column-end: span 4;
		}
	}
}

@media (min-width: 64em) {
	.card-size {
		width: calc(25% - 24px);
	}
	@supports (display: grid) {
		.card-size {
			width: auto;
			grid-column-end: span 3;
		}
	}
}

#FMEResourcePanel-button {
	justify-items: flex-start;
}
#BlueCruiseResourcePanel-button {
	justify-items: flex-start;
}
#EVResourcePanel-button {
	justify-items: flex-start;
}
#VideoResourcePanel-button {
	justify-items: flex-start;
}

#ModeleResourcesAccordion .fmc-accordion .fmc-accordion__panel.resources-accordion .fmc-accordion__body {
	background-color: var(--fds-color--gray1) !important;
}

.resource-vid-image {
	display: block;
	width: 100%;
	height: auto;
}

.superscript {
	font-size: 50%;
	vertical-align: super;
}

.employeePref-table {
	width: 100%;
	border: none !important;
	border-collapse: collapse;

	align-items: left;
}

// .employeePref-table th {
//     text-align: left;
// }

.employeePref-table tr {
	border-bottom: 1px solid #9c9c9c;
}
// .employeePref-table tr:nth-last-child(-n + 1){
//     border-bottom:none;
// }

.employeePref-table td {
	padding: 15px;
	word-wrap: break-word;
	padding-top: 2rem;
	padding-bottom: 2rem;
	color: #4d4d4d;
}

.employeePref-table th {
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.employeePref-table-head-row th {
	color: var(--fds-color--primary);
	font-weight: normal;
}

.employeePref-table td:first-child {
	text-align: center;
	width: 15%;
}
.employeePref-table td:nth-child(2) {
	padding-left: 1em;
	width: 15%;
	text-align: center;
}
.employeePref-table td:nth-child(3) {
	padding-left: 1em;
	width: 20%;
	text-align: center;
}

// 50
.employeePref-table td:nth-child(4) {
	padding-left: 1em;
	width: 25%;
	text-align: center;
}
.employeePref-table td:nth-child(5) {
	padding-left: 1em;
	width: 15%;
	text-align: center;
}

.dealer-role-search {
	padding-top: 15px;
}
.dealer-role-search .fds-section-search__combobox-wrapper .fds-search__input-wrapper {
	// min-width: 600px !important;
	max-width: 100%;
}
.dealer-role-search .fds-section-search__combobox-wrapper {
	padding-right: 0;
}

.dealer-role-search .fds-section-search__combobox-wrapper .fds-search__combobox-wrapper {
	padding-top: 0 !important;
	padding-top: 15px;
}
.roleSearch {
	border: 1px solid grey;
	position: relative;
	float: left;
	z-index: 0;
	overflow: hidden;
}

.dealerRoleSearchResults {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 100;
	overflow: visible;
}

.dropdown-roles {
	position: relative;
	color: #333;
	cursor: default;
}

.dropdown-roles .arrow {
	border-color: #999 transparent transparent;
	border-style: solid;
	border-width: 5px 5px 0;
	content: " ";
	display: block;
	height: 0;
	margin-top: 0.3rem;
	position: absolute;
	right: 10px;
	top: 20px;
	width: 0;
}

.dropdown-roles .arrow .open {
	border-color: transparent transparent #999;
	border-width: 0 5px 5px;
}

.dropdown-roles .options {
	display: none;
	background-color: #fff;
	border: 1px solid #ccc;
	box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
	box-sizing: border-box;
	margin-top: -1px;
	max-height: 200px;
	overflow-y: auto;
	position: absolute;
	top: 100%;
	width: 100%;
	z-index: 1000;
	-webkit-overflow-scrolling: touch;
}

.dropdown-roles .options.open {
	display: block;
}

.dropdown-roles .option {
	box-sizing: border-box;
	color: rgba(51, 51, 51, 0.8);
	cursor: pointer;
	display: block;
	padding: 8px 10px;
}

.dropdown-roles .option.selected,
.dropdown-roles .option:hover {
	background-color: #f2f9fc;
	color: #333;
}

.mobile-employee-entry {
	border-bottom: 1px solid #9c9c9c;
	padding: 10px;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.mobile-employee-row {
	justify-content: space-between;
}

.add-employee-modal-container {
	position: fixed;
	z-index: 101;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.4);
}

.add-employee-modal {
	position: fixed;
	top: 15%;
	border-radius: 5px;
	background-color: white;
	padding: 3rem;
	width: 25%;
}

.add-employee-modal-content {
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.add-employee-topRow {
	border-bottom: 1px solid #9c9c9c;
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding-bottom: 1rem;
}

.om-fmc-tabs.fmc-tabs.modeleresourcetabs .fmc-tabs__tablist-wrapper::before {
	display: unset;
	border: none;
	height: 0px;
}

.om-fmc-tabs.fmc-tabs.modeleresourcetabs .fmc-tabs__tablist-wrapper {
	display: unset !important;
}

.employee-list-accordion .fmc-accordion__button .fmc-accordion__button-title {
	color: var(--fds-color--primary);
	text-align: left;
	width: 100%;
}

.employee-list-accordion-top {
	border-top: 1px solid black;
}
.employee-list-accordion .fmc-accordion__body .fmc-accordion__content {
	background-color: #f4f4f4;
}

.employee-list-title {
	padding-bottom: 1rem;
}

.employee-list-item {
	padding-bottom: 2rem;
	font-size: 14px;
	color: #4d4d4d;
}

.employee-list-container {
	padding-left: 1rem;
	padding-top: 1rem;
}

.no-access-title {
	font-size: 2.5rem;
	padding-bottom: 2.5rem;
}

.no-access-banner-text-container {
	position: absolute;
	top: 30px;
	left: 30px;
	max-width: 52%;
	max-height: 295px;
}

.no-access-banner-hero {
	color: white;
	font-size: 4rem;
	line-height: 5rem;
}

.no-access-banner-secondary-text {
	color: #fff;
	font-size: 1.7rem;
	max-width: 82%;
}

.no-access-banner-secondary-text a {
	color: #fff;
	text-decoration: underline;
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
	.no-access-banner-text-container {
		max-width: 65%;
	}
	.no-access-banner-hero {
		font-size: 3rem;
		line-height: 4rem;
	}
}

.no-access-mobile-hero {
	display: flex;
	flex-direction: column;
}

.no-access-mobile-hero-area {
	background-color: #00142e;
	padding: 20px 30px;
}

.no-access-mobile-title {
	color: #fff;
	font-size: 3.5rem;
	line-height: 4rem;
	padding: 1rem 0 1rem 0;
	white-space: pre-wrap;
}

.no-access-mobile-text {
	color: #fff;
	font-size: 2rem;
	line-height: 2.5rem;
}

.mobile-no-access-content {
	padding: 30px;
}

.no-access-mobile-contact {
	border-top: 2px solid #4d4d4d;
	padding-top: 3rem;
}

.fmc-top-banner.fmc-top-banner--persistent.fmc-top-banner--success.no-access-success-banner {
	position: relative;
	z-index: 500;
	max-width: 1680px;
}

.fmc-top-banner.fmc-top-banner--persistent.fmc-top-banner--success.no-access-success-banner .fmc-top-banner__inner {
	animation: growDown 300ms ease-in-out forwards;
	transform-origin: top center;

	@keyframes growDown {
		0% {
			transform: scaleY(0);
		}
		80% {
			transform: scaleY(1.1);
		}
		100% {
			transform: scaleY(1);
		}
	}
}

.dash-icon-legend {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.dash-legend-icon {
	display: flex;
	flex-direction: row;
	white-space: nowrap;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.dash-icon-legend-mobile {
	margin: 0 2rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.mobile-icon-legend-column {
	display: flex;
	flex-direction: column;
}

.dash-legend-icon p {
	font-size: 13px;
	color: #4d4d4d;
	padding-left: 5px;
	white-space: nowrap;
}

.dash-icon-legend-img {
	max-height: 13px;
	margin-top: 6px;
	padding-right: 5px;
}

.dealerTab-Section {
	width: 100%;
	padding: 2.5rem;
	background-color: #ffff;
	border-radius: 12px;
	box-shadow: 0px 4px 7px 1px rgba(166, 166, 166, 0.387);
}

@media (max-width: 1024px) {
	.dealerTab-Section {
		width: 100%;
		padding: 2.5rem 2rem;
		background-color: #ffff;
		border-radius: 0;
		box-shadow: unset;
	}

	.mecpSummarydividerTop {
		border-top: 1px solid black;
	}
	.mecpSummarydividerbottom {
		border-bottom: 1px solid black;
	}

	.mecpdivider-right {
		border-right: 0;
	}
}
.transMemo-Section {
	width: 100%;
	padding: 2.5rem;
	background-color: #ffff;
	border: 2px solid #999999;
	border-radius: 12px;
}

.MeQ-percent {
	font-size: 12px;
	color: #4d4d4d;
}

.row-status-icon {
	max-height: 26px;
	max-width: 32px;
}

.chevron-style {
	transform: translate(50%);
}

// LOA/Waiver Section

.loa-tracker-container {
	background-color: #fff;
	padding: 2.5rem 0;
}

.loa-steps-container {
	padding: 25px;
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 3rem 5rem 1rem transparent, 0.5rem -0.03rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.loa-steps-top-text {
	font-size: 13.33px;
	color: var(--fds-color--gray3);
}

.loa-steps-text-container {
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid var(--fds-color--gray3);
}

.loa-single-step {
	padding: 25px 0;
}

//LOA Accordions

.LOA-Accordion-Container {
	padding-left: 3.5rem;
	padding-top: 1rem;
}
.LOA-Category-Accordion .fmc-accordion__panel .fmc-accordion__button {
	border-top: 1px solid black;
	justify-items: flex-start;
}

.LOA-Category-Accordion .fmc-accordion__panel .fmc-accordion__body {
	background-color: #f4f4f4;
}

.LOA-InnerSubCat {
	margin-left: 3rem;
	margin-bottom: 1em;
	display: flex;
	flex-direction: column;
}

.mobileEmailFont {
	font-size: 15px;
}

.LOA-TopLevel-Accordion label {
	margin-bottom: 1rem;
}

.LOA-InnerSubCat label:first-child {
	margin-top: 1rem;
}

.LOA-button-outline {
	border: 1px solid var(--fds-color--primary);
	border-radius: var(--radius);
}

.margin-left-3 {
	margin-left: 3rem;
}

.masterSelections {
	width: 230px;
	padding: 2rem 0;
}

.buttonCustomTop {
	top: calc(50% - -0.2rem) !important;
}

.narrowMDButton {
	min-height: 90px;
}

.reportSectionButton {
	width: 100%;
	height: 100%;
	border: 3px solid #066fef;
	border-radius: 10px;
	background-color: #ffff;
	box-shadow: var(--fmc-elevation__box-shadow--layer1);
	text-align: center;
	color: var(--fds-color--primary);
	font-size: 2.2rem;
	padding: 2rem 1.3rem;
	line-height: 30px;
	letter-spacing: normal;
	display: flex;
	justify-content: center;
	align-items: center;
}

.reportPageButton {
	flex: 1 1 10rem;
	padding: 2rem;
}
.reportPageButtonShort {
	flex: 0.7 1 10rem;
	padding: 2rem;
}

.disabledInput {
	border: 1px solid var(--fds-color--disabled2);
}

.disabledInputText {
	color: var(--fds-color--disabled2) !important;
}

.disabledButtonStyle {
	--bg-color: var(--color-disabled);
	--bg-color-hover: var(--color-disabled);
	--bg-color-active: var(--color-disabled);
	--border-accent: transparent;
	--shadow: none;
	--shadow-hover: none;
	--shadow-active: none;
	--scale: var(--scale);
	--cursor: not-allowed;
}

.alignJustify {
	text-align: justify;
}

.userDropDown {
	position: absolute;
	top: 168px;

	z-index: 100;
	background-color: #ffff;
	box-shadow: var(--fmc-elevation__box-shadow--layer1);
	border: 1px solid black;
}

@media (min-width: 1920px) {
	.userDropDown {
		right: -webkit-calc(50% - 810px);
		right: calc(50% - 810px);
	}
}
@media (min-width: 1601px) and (max-width: 1919px) {
	.userDropDown {
		right: -webkit-calc(50% - 800px);
		right: calc(50% - 800px);
	}
}
@media (min-width: 1440px) and (max-width: 1600px) {
	.userDropDown {
		right: -webkit-calc(50% - 690px);
		right: calc(50% - 690px);
	}
}
@media (min-width: 1024px) and (max-width: 1439px) {
	.userDropDown {
		right: -webkit-calc(50% - 500px);
		right: calc(50% - 500px);
	}

	.reportPageButtonShort {
		flex: 1 1 10rem;
		padding: 2rem;
	}
}
@media (max-width: 1024px) {
	.masterSelections {
		width: unset;
		min-width: 230px;
	}
}

.popup {
	min-width: 15rem;
	padding: 2rem 1.5rem;
	margin: 0 auto;
	position: relative;
	text-align: center;
	z-index: 999;
}
.popup:after {
	content: "";
	height: 100%;
	width: 100%;
	border-radius: 4px;
	position: absolute;
	top: 0;
	left: 0;
	box-shadow: 0 1px 14px rgba(0, 0, 0, 0.2);
}
.popup:before {
	content: "";
	width: 20px;
	height: 20px;
	transform: rotate(45deg);

	background: #fff;
	position: absolute;
	z-index: 998;
}
/*
Different arrow positioning
*/
.popup.arrow-top:before {
	left: calc(50% - 10px);
	top: -11px;
	border-left: 1px solid black;
	border-top: 1px solid black;
}
.popup.arrow-right:before {
	top: calc(50% - 10px);
	right: -8px;
}
.popup.arrow-bottom:before {
	left: calc(50% - 10px);
	bottom: -8px;
}
.popup.arrow-left:before {
	top: calc(50% - 10px);
	left: -8px;
}

/* 
Popup wrapper
This is where we add the white background and padding of the content within the popup
*/
.popup-wrapper {
	width: 100%;
	border-radius: 4px;
	background: #fff;
	position: relative;
	z-index: 999;
}

.iconRotate {
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	transform: rotate(180deg);
	transition: 100ms linear all;
	display: inline-block;
}

.navTextResize {
	font-size: 1.8rem;
}

@media (max-width: 89em) {
	.navTextResize {
		font-size: 1.5rem;
	}
}

.notifConfirmationBanner {
	min-height: 10px;
	min-width: 100%;
	position: fixed;
	z-index: 500;
}

.dealerNotification {
	background-color: transparent;
	color: white;
	text-decoration: none;

	position: relative;
	display: inline-block;
	border-radius: 2px;
}

.dealerNotification .notificationBadge {
	position: absolute;
	color: white;
	background-color: red;
	font-weight: 500;
	height: 1.8rem;
	width: 2rem;
	padding-top: 0.2rem;
	top: -12px;
	left: -3px;
	border-radius: 50%;
	text-align: center;
	font-family: "FordAntenna";
}

.preferencesBadge {
	color: white;
	font-weight: 500;
	background-color: red;
	height: 2rem;
	width: 2rem;
	border-radius: 50%;
	display: inline-block;
	text-align: center;
}

.fmc-tooltip.fmc-tooltip--opened.fmeQ-tooltip .fmc-tooltip__wrapper {
	width: 35rem !important;
	max-height: 30rem;
}

.fmc-tooltip.fmc-tooltip--opened.fmeQ-tooltip .fmc-tooltip__wrapper .fmc-tooltip__content {
	white-space: normal !important;
	font-size: 1.8rem;
	color: var(--fds-color--primary);
	font-stretch: 65%;
	line-height: 2rem;
	height: 16rem !important;
	width: 42rem !important;
	max-width: unset;
	padding: 1rem 2rem;
}
.fmc-tooltip.fmc-tooltip--opened.fmeQ-tooltip .fmc-tooltip__wrapper .fmc-tooltip__content ul {
	z-index: 289;
}

.chargInfra-icon {
	height: 50px;
	width: 50px;
}

.dealerLandingButton {
	width: 100%;
	height: 100%;

	border: 5px solid transparent;
	box-shadow: 0px 0px 0px 2px var(--fds-color--primary);
	border-radius: 17px;
	background-color: #ffff;
	text-align: center;
	color: var(--fds-color--primary);
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 10;
	box-sizing: border-box;
}

.dealerLandingButtonSelected {
	width: 100%;
	height: 100%;
	border: 7px solid #066fef;
	border-radius: 17px;
	background-color: #ffff;
	text-align: center;
	color: var(--fds-color--primary);
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 10;
	box-sizing: border-box;
	margin: -7px;
}

.landingButton-Mobile {
	width: 100%;
	height: 100%;
	border-top: 2px solid #00095b;
	border-bottom: 2px solid #00095b;
	background-color: #fff;
	text-align: center;
	color: var(--fds-color--primary);
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 10;
	box-sizing: border-box;
}
.landingButton-MobileSelected {
	width: 100%;
	height: 100%;
	border-top: 5px solid #066fef;
	border-bottom: 5px solid #066fef;
	margin-top: -5px;
	margin-bottom: -5px;
	text-align: center;
	background-color: #fff;
	color: var(--fds-color--primary);
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 10;
	box-sizing: border-box;
}

.landingButtonPadding {
	padding: 1.4rem 2rem;
}

.landingButtonHead {
	font-size: 2.4rem;
	font-weight: bold;
	line-height: 32px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	letter-spacing: 0px;
}

.landingButtonHeader {
	background-color: var(--fds-color--primary);
	color: white;
	padding-top: 1rem;
	padding-bottom: 0.8rem;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	box-shadow: 0px 0px 0px 5px var(--fds-color--primary);
}

.dealerLandingButtonSelected .landingButtonHeader {
	box-shadow: unset;
}

.landingHeadDivider {
	text-decoration: underline;
	text-underline-offset: 0.8rem;
	text-decoration-color: #00095b;
}

.dealerLandingButtonSelected .landingHeadDivider {
	text-decoration: underline;
	text-underline-offset: 0.8rem;
	text-decoration-color: #066fef;
}

.landingHeadDivider2 {
	height: 1px;
	width: 60%;
	margin: 1px auto;
	float: left;
}

.landingHeadDivider2:after {
	content: "";
	display: block;

	border-bottom: 1px solid black;
}

.dealerLandingButtonSelected .landingHeadDivider2:after {
	content: "";
	display: block;

	border-bottom: 1px solid #066fef;
}

.landingButtonBody {
	padding: 1rem;
}

.landingButton-subHead {
	font-size: 1.6rem;
	font-weight: bold;
	line-height: 18px;
	text-align: center;
	letter-spacing: 0px;
}

.landingButton-status {
	font-size: 1.3rem;
	letter-spacing: 0px;
	font-weight: medium;
}

.landingButton-icon {
	height: 30px;
	width: 30px;
}

.lockSpace {
	width: 10rem;
	display: flex;
	justify-content: center;
	margin-top: auto;
	margin-bottom: auto;
}
.landingLock {
	height: 5rem;
	width: 5rem;
}

.col-4-half {
	flex-basis: 30%;
	max-width: 30%;
}

.health-check-row {
	padding-top: 0.3rem;
	padding-bottom: 0.3rem;
	display: flex;
	flex-direction: row;
	align-items: baseline;
}

.landingButton-divider-right {
	border-right: 1px solid #979797;
}

.landing-subButton {
	font-size: 1.8rem;
	letter-spacing: 1px;
	font-stretch: condensed;
	align-self: center;
}

.landingBatteryContainer {
	width: 100%;
	background-color: #fff;
	padding: 2.5rem 0rem;
}

.landing-med-center {
	justify-content: center;
	align-items: center;
}

@media (min-width: 1024px) and (max-width: 1439px) {
	.col-4-half {
		flex-basis: 29%;
		max-width: 29%;
	}

	.landingHeadDivider {
		text-decoration: none;
		border-bottom: 1px solid black;
		padding-bottom: 0.5rem;
	}

	.landingButton-divider-right {
		border-right: none;
		border-bottom: 1px solid #979797;
	}

	.landing-med-center {
		justify-content: center;
		align-items: center;
	}

	.landingButtonHeader {
		padding: 1rem;
	}

	.health-check-row {
		padding: 1rem;
	}

	.healthCheck-text {
		font-size: calc(1.6rem + (1.8 - 1) * ((100vw - 64rem) / (1920 - 64)));
		white-space: normal;
		text-align: left;
		letter-spacing: 0px;
	}

	.healthCheck-medium-padding {
		margin-top: 1.5rem;
		margin-bottom: auto;
	}
}

.mid-divider {
	width: 50%;
	margin: 0 auto;
	padding: 10px;

	border-bottom: 1px solid #979797;
}

.mobileLockspaceDivider {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.mobileLockspaceDividerline {
	min-height: 1px;
	background-color: #979797;
	width: 100%;
}

.dashTabContainer {
	width: 100%;
	overflow: clip;
}

.commitProgSummary {
	color: #4d4d4d;
	letter-spacing: 0px;
}

.commitProgSummary span {
	color: #066fef;
	font-weight: bold;
}

.transMemo-text {
	font-size: 1.5rem;
	line-height: 1.8rem;
	letter-spacing: 0;
}

.unlock-divider {
	background: linear-gradient(#000, #000) no-repeat center/1px 100%;
	min-width: 1px;
	min-height: 1.3rem;
}

.mecp-lock-container {
	position: relative;
	left: calc(100% - 52%);
	max-width: fit-content;
}

.letter-spacing-0 {
	letter-spacing: 0px;
}

.fmc-button.dlalign [class*="fds-font--ford-icons"]::before {
	top: calc(50% - 0.9rem);
}

.buysell-button-text {
	margin-top: 2rem;
	margin-bottom: 4rem;
	padding: 0 2rem;
	text-align: start;
}

.buysellNoButton {
	cursor: default;
}

.sunsetText {
	font-size: 2.3rem;
	color: white;
	line-height: 3rem;
}

.sunsetText a {
	color: white;
	text-decoration: underline;
}
.sunsetText a:hover {
	color: white;
	text-decoration: none;
}

@media screen and (max-width: 1024px) {
	.sunsetText {
		font-size: 2rem;
		color: white;
		line-height: 2.3rem;
	}

	.sunsetText a {
		color: white;
		text-decoration: underline;
	}
	.sunsetText a:hover {
		color: white;
		text-decoration: none;
	}
}

.videoContainer {
	position: relative;
	overflow: hidden;
	width: 100%;
	padding-top: 56.25%;
}

.softLandingVideo {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
}

.softLandingResource {
	font-stretch: 100%;
	padding: 2rem;
	letter-spacing: 2px;
}

.opacityZero {
	opacity: 0;
}

.exPhotoContainer {
	height: 130px;
	width: 210px;
}
