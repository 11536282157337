@use "sass:math";

.fmc-cards {
  --border: 0;
  --content-padding: 3rem 2rem;
  --footer-border: 1px solid var(--fds-color--black-40);
  --footer-alignment: center;
  --play-opacity: 0.75;
  --shadow: inset 0 0 0 transparent, #{$fds-elevation__box-shadow--layer1};

  position: relative;
  height: 100%;

  @include fds-set-brand(lincoln) {
    --border: 1px solid var(--fds-color--disabled2);
    --content-padding: 3rem;
    --footer-border: 0;
    --footer-alignment: flex-start;
    --shadow: none;
  }
}

.fmc-cards__card {
  display: flex;
  flex-direction: column;
  border-radius: fds-rem(3px);
  overflow: hidden;
  border: var(--border);
  background-color: var(--fds-color--white);
  box-shadow: var(--shadow);

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: var(--fds-color--primary) solid fds-rem(1px);
    outline-offset: fds-rem(10px);
  }
}

.fmc-cards__video {
  cursor: pointer;
  width: 100%;
  height: 0;
  padding-top: math.div(9, 16) * 100%;

  &::before {
    @extend %fds-icons;

    content: $fds-font--ford-icons__play-symbol;
    color: var(--fds-color--white);
    font-size: 4rem;
    left: calc(50% + 0.5rem);
    z-index: 2;
  }
  // updated from 6rem

  &::after {
    content: '';
    left: 50%;
    z-index: 1;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    background-color: var(--fds-color--primary);
  }

  // updated from 10 x 10 rem
  &::before,
  &::after {
    pointer-events: none;
    position: absolute;
    top: 50%;
    opacity: var(--play-opacity);
    transform: translate(-50%, -50%);
    transition: opacity var(--fmc-transition-duration) linear;
  }

  @include fds-set-hover {
    --play-opacity: 1;
  }
}

.fmc-cards__video--full {
  padding-top: (3 * 0.25) * 100%;
}

.fmc-cards__video--playing {
  &::before,
  &::after {
    display: none;
  }
}

.fmc-cards__video img,
.fmc-cards__video video {
  position: absolute;
  top: 0;
}

.fmc-cards__card-carousel {
  height: 100%;
}

.fmc-cards__card--horizontal {
  & .fmc-cards__media-area {
    order: -1;
  }

  @media (min-width: map-get($fds-breakpoints, 'fds-md')) {
    flex-direction: row;

    & .fmc-cards__footer {
      // An alternative to align-items: start is to instead set
      // flex-direction: row for horizontal cards.
      align-items: start;
    }

    & .fmc-cards__media-area {
      order: unset;
      flex: 50%;
      display: flex;

      & img {
        object-fit: cover;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}

.fmc-cards__card--selected {
  box-shadow: inset 0 0 0 transparent, $fds-elevation__box-shadow--layer1, 0 0 0 fds-rem(1px) var(--fds-color--primary);
}

@media (min-width: map-get($fds-breakpoints, 'fds-md')) {
  .fmc-cards__card--horizontal-25 {
    .fmc-cards__media-area {
      flex: 25%;
    }

    .fmc-cards__content-wrapper,
    .fmc-cards__content {
      flex: 75%;
    }
  }

  .fmc-cards__card--horizontal-75 {
    .fmc-cards__media-area {
      flex: 75%;
    }

    .fmc-cards__content-wrapper,
    .fmc-cards__content {
      flex: 25%;
    }
  }

  .fmc-cards__card--horizontal-reverse {
    // The content-wrapper class is included for backwards compatibility,
    // but it is not necessary to use it in new projects.
    .fmc-cards__content-wrapper,
    .fmc-cards__content {
      order: -1;
    }
  }
}

.fmc-cards__card--quiet {
  background-color: unset;
  box-shadow: unset;
  border-radius: unset;
  overflow: visible;

  .fmc-cards__media-area {
    border-radius: fds-rem(3px);
    overflow: hidden;
    box-shadow: inset 0 0 0 transparent, $fds-elevation__box-shadow--layer1;
  }
}

.fmc-cards__media-area {
  position: relative;

  > img {
    display: block;
    width: 100%;
  }
}

.fmc-cards__alert {
  position: absolute;
  bottom: 0;
  z-index: 1;
  width: 100%;
  padding: fds-rem(10px 20px);
  @extend %fmc-type--content2;
  color: var(--fds-color--white);
  background-color: var(--fds-color--primary);
}

.fmc-cards__alert--success {
  background-color: var(--fds-color--success1);
}

.fmc-cards__alert--error {
  background-color: var(--fds-color--error1);
}

.fmc-cards__alert--caution {
  background-color: var(--fds-color--caution2);
}

.fmc-cards__content {
  padding: var(--content-padding);
  display: flex;
  flex-direction: column;
  flex: 50%;

  > *:not(:last-child) {
    margin-bottom: fds-rem(20px);
  }
}

.fmc-cards__content-wrapper {
  display: flex;
  flex: 50%;
}

.fmc-cards__title {
  @extend %fmc-type--heading6;
}

.fmc-cards .fmc-cards__title {
  @include fds-set-brand(lincoln) {
    @extend %fmc-type--heading5;
    font-size: 2.4rem;
  }
}

.fmc-cards__subtitle {
  @extend %fmc-type--subtitle2;
}

.fmc-cards__body {
  @extend %fmc-type--body1;
}

.fmc-cards__footer {
  display: flex;
  flex-direction: column;
  align-items: var(--footer-alignment);
  padding: fds-rem(30px) 0 0 0;
  margin: auto 0 0;
  border-top: var(--footer-border);

  > *:not(:last-child) {
    margin-bottom: fds-rem(20px);
  }
}

.fmc-cards__quiet-card {
  background: none;
  box-shadow: none;

  & .fmc-cards__image-wrapper {
    box-shadow: inset 0 0 0 transparent, $fds-elevation__box-shadow--layer1;
  }
}
