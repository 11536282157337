.fmc-breadcrumbs {
  --breadcrumb-current-color: var(--fds-color--gray2);
  --breadcrumb-icon-size: 0.8rem;

  display: flex;
  list-style: none;
  margin: 1.6rem auto;
  align-items: center;
  flex-wrap: wrap;

  .fmc-breadcrumbs__current {
    color: var(--breadcrumb-current-color);
    pointer-events: none;
  }

  .fmc-breadcrumbs__link,
  .fmc-breadcrumbs__current {
    display: inline-block;
    padding: 0.8rem;
  }

  .fmc-breadcrumbs__link:hover {
    text-decoration: underline;
  }

  .fmc-breadcrumbs__link:focus-visible {
    outline: var(--fds-color--primary) solid fds-rem(1px);
    color: var(--fds-color--secondary);
  }

  .fds-icon {
    font-size: var(--breadcrumb-icon-size);
  }
}
