.fmc-accordion {
  --button-font-size: #{fds-rem(11px)};
  --button-line-height: #{fds-rem(16px)};
  --title-font-size: #{fds-rem(16px)};
  --title-line-height: #{fds-rem(24px)};
  --title-letter-spacing: #{fds-rem(1px)};
  --title-text-transform: none;
  --panel-border: none;
  --button-padding: #{fds-rem(16px)};
  --button-text-color: var(--fds-color--white);
  --button-background-color: var(--fds-color--primary);
  --button-background-color-active: var(--fds-color--secondary);
  --body-background-color: var(--fds-color--white);
  --body-text-color: var(--fds-color--white);
  --expand-icon-color: inherit;
  --expand-icon-size: inherit;
  --expand-icon-symbol: '#{$fds-font--ford-icons__chevron-down}';
  --expand-icon-symbol--expanded: '#{$fds-font--ford-icons__chevron-down}';
  --expand-icon-transform: none;
  --expand-icon-transform--expanded: rotate(-180deg);
  --content-padding: #{fds-rem(16px)};
  --outline-color: var(--fds-color--white);
  --outline-offset: #{fds-rem(-10px)};
  --gap-sm: #{fds-rem(8px)};
  --gap-md: #{fds-rem(16px)};
  --transition: var(--fmc-transition-duration) var(--fmc-transition-timing);

  @include fds-set-brand(lincoln) {
    --title-font-size: #{fds-rem(17px)};
    --title-line-height: #{fds-rem(20px)};
    --title-letter-spacing: #{fds-rem(2px)};
    --title-text-transform: uppercase;
    --panel-border: 1px solid var(--fds-color--disabled2);
    --button-padding: #{fds-rem(20px 10px)};
    --button-text-color: var(--fds-color--primary);
    --button-text-color-hover: var(--fds-color--primary);
    --body-text-color: var(--fds-color--gray2);
    --button-background-color: var(--fds-color--gray2);
    --button-background-color-active: var(--fds-color--gray2);
    --body-background-color: var(--fds-color--gray2);
    --expand-icon-color: var(--fds-color--secondary);
    --expand-icon-size: #{fds-rem(16px)};
    --expand-icon-symbol: '#{$fds-font--ford-icons__plus}';
    --expand-icon-symbol--expanded: '#{$fds-font--ford-icons__minus}';
    --expand-icon-transform: none;
    --expand-icon-transform--expanded: none;
    --content-padding: #{fds-rem(10px 10px 40px 10px)};
    --outline-color: var(--fds-color--primary);
    --outline-offset: 0;
  }

  position: relative;
  width: 100%;

  &.fmc-accordion--light {
    --outline-color: var(--fds-color--primary);
    --button-text-color: var(--fds-color--primary);
    --button-background-color: var(--fds-color--white);
    --button-background-color-active: var(--fds-color--gray1);
    --body-background-color: var(--fds-color--white);

    &.fds-color__bg--light {
      .fmc-accordion__panel {
        margin-bottom: 0;
      }

      --panel-border: 1px solid var(--fds-color--disabled2);
      --button-background-color-active: var(--fds-color--white);
      --body-background-color: var(--fds-color--gray1);

      @include fds-set-brand(lincoln) {
        --button-background-color: var(--fds-color--gray2);
        --button-background-color-active: var(--fds-color--gray2);
        --body-background-color: var(--fds-color--gray2);
        --button-text-color: var(--fds-color--primary);
        --body-text-color: var(--fds-color--primary);
      }
    }

    &.fds-color__bg--dark {
      @include fds-set-brand(lincoln) {
        --button-background-color: var(--fds-color--gray2);
        --button-background-color-active: var(--fds-color--gray2);
        --body-background-color: var(--fds-color--gray2);
        --body-text-color: var(--fds-color--primary);
      }
    }
  }

  &.fds-color__bg--light {
    @include fds-set-brand(lincoln) {
      --button-background-color: var(--fds-color--primary);
      --button-background-color-active: var(--fds-color--primary);
      --button-text-color: var(--fds-color--gray2);
      --body-background-color: var(--fds-color--primary);
    }
  }

  .fmc-accordion__panel {
    border-top: var(--panel-border);
    border-bottom: var(--panel-border);

    &:not(:last-child) {
      margin-bottom: fds-rem(2px);
      @include fds-set-brand(lincoln) {
        border-bottom: none;
      }
    }
    @include fds-set-brand(lincoln) {
      margin-bottom: 0;
    }

    &.fmc-accordion--active,
    &.fmc-accordion--expanded {
      --button-background-color: var(--button-background-color-active);
    }

    &.fmc-accordion--expanded {
      --expand-icon-symbol: var(--expand-icon-symbol--expanded);
      --expand-icon-transform: var(--expand-icon-transform--expanded);

      .fmc-accordion__body {
        height: auto;
      }
    }
  }

  .fmc-accordion__button {
    display: grid;
    grid:
      '.      label        expand'
      'icon   title        expand'
      '.      descriptor   expand'
      '.      feedback     expand'
      / auto 1fr auto;
    justify-items: flex-start;
    align-items: center;
    width: 100%;
    padding: var(--button-padding);
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    transition: background-color var(--transition);

    background-color: var(--button-background-color);
    color: var(--button-text-color);
    font-size: var(--button-font-size);
    line-height: var(--button-line-height);
    text-align: start;

    @media (min-width: map-get($fds-breakpoints, 'fds-md')) {
      grid:
        '.      label        feedback   expand'
        'icon   title        feedback   expand'
        '.      descriptor   feedback   expand'
        / auto 1fr auto auto;
    }

    &:hover {
      --button-background-color: var(--button-background-color-active);
    }

    &:focus-visible {
      outline-offset: var(--outline-offset);
      outline: 1px solid var(--outline-color);
    }
  }

  .fmc-accordion__button-icon {
    grid-area: icon;
    margin-right: var(--gap-sm);
  }

  .fmc-accordion__button-label {
    grid-area: label;
    margin-bottom: var(--gap-sm);
  }

  .fmc-accordion__button-label-icon {
    vertical-align: bottom;
  }

  %fmc-accordion__button-title {
    grid-area: title;
    font-size: var(--title-font-size);
    text-transform: var(--title-text-transform);
    line-height: var(--title-line-height);
    letter-spacing: var(--title-letter-spacing);
  }

  .fmc-accordion__button-title {
    @extend %fmc-accordion__button-title;
  }

  .fmc-accordion__button-descriptor {
    grid-area: descriptor;
    margin-top: var(--gap-sm);
  }

  .fmc-accordion__button-feedback {
    grid-area: feedback;
    margin-top: var(--gap-sm);
    @media (min-width: map-get($fds-breakpoints, 'fds-md')) {
      justify-self: end;
      margin-top: 0;
      margin-left: var(--gap-md);
    }
  }

  %fmc-accordion__button-expand {
    grid-area: expand;
    margin-left: var(--gap-md);
    transform: var(--expand-icon-transform);
    transition: transform var(--transition);
  }

  %fmc-accordion__button-expand-after {
    color: var(--expand-icon-color);
    font-size: var(--expand-icon-size);
    line-height: 0;
    content: var(--expand-icon-symbol);
  }

  .fmc-accordion__button-expand {
    @extend %fmc-accordion__button-expand;

    &::after {
      @extend %fmc-accordion__button-expand-after;
    }
  }

  .fmc-accordion__button-text {
    // For backwards compatibility
    @extend %fmc-accordion__button-title;

    & + .fds-icon {
      @extend %fmc-accordion__button-expand;

      &::after {
        @extend %fmc-accordion__button-expand-after;
      }
    }
  }

  .fmc-accordion__body {
    overflow: hidden;
    transition: height var(--transition);
    text-overflow: clip;
    height: 0;
    background-color: var(--body-background-color);
  }

  .fmc-accordion__content {
    padding: var(--content-padding);
  }

  @include fds-set-brand(lincoln) {
    .fmc-type--body1,
    .fmc-type--body1 a:link {
      color: var(--body-text-color);
    }
  }
}
