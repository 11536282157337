// This CSS applies to both the Checkbox and Radio Button components.

fieldset.fmc-check {
  // Fieldset usually has a very "strange" look. The settings below
  // attempt to override the standard look and make it look more like
  // your general "<label> + <input>".
  border: 0;
  padding: 0;
  margin: 0;
  min-width: 0;
}

.fmc-check {
  --disabled-color: var(--fds-color--disabled3);
  --checked-color: var(--fds-color--primary);
  --text-color: var(--fds-color--gray3);
  --text-color-disabled: var(--fds-color--gray1);
  --text-background-color-disabled: var(--disabled-color);
  --button-outline-color: var(--fds-color--gray2);
  --button-fill-color-disabled: var(--disabled-color);
  --button-fill-color-checked: transparent;
  --fill-color: transparent;
  --button-diameter: #{fds-rem(16px)};
  --button-outline-thickness: #{fds-rem(2px)};
  --button-outline-thickness-checked: #{fds-rem(2px)};
  --button-label-gap: #{fds-rem(24px)};
  --vertical-gap: #{fds-rem(16px)};
  --line-height: #{fds-rem(24px)};

  // Checkbox specific
  --check-size: #{fds-rem(10px)};
  --check-color: var(--checked-color);
  --check-radius: #{fds-rem(2px)};

  // Radio Button Specific
  --dot-diameter: #{fds-rem(8px)};
  --dot-color: var(--checked-color);

  @include fds-set-brand(lincoln) {
    --disabled-color: var(--fds-color--gray4);
    --checked-color: var(--fds-color--secondary);
    --text-color: var(--fds-color--primary);
    --text-color-disabled: var(--disabled-color);
    --text-background-color-disabled: transparent;
    --button-outline-color: var(--fds-color--primary);
    --button-fill-color-disabled: transparent;
    --button-diameter: #{fds-rem(20px)};
    --button-outline-thickness: #{fds-rem(1px)};
    --button-outline-thickness-checked: #{fds-rem(2px)};
    --check-size: #{fds-rem(20px)};
    --check-color: #{$fds-color--gray1};
    --check-radius: 0;
    --dot-diameter: #{fds-rem(10px)};
    --button-label-gap: #{fds-rem(12px)};
    --vertical-gap: #{fds-rem(8px)};
    --line-height: #{fds-rem(24px)};
  }

  display: flex;
  flex-direction: column;

  legend {
    padding: 0;
    margin-bottom: var(--vertical-gap);
    color: var(--text-color);
  }

  &.fmc-check--horizontal {
    flex-direction: row;

    label:not(:first-of-type),
    .fmc-check__button:not(:first-of-type) {
      margin-left: fds-rem(28px); // 28px margin + 4px padding on previous label = (4 x 8px)
    }
  }

  &:not(.fmc-check--horizontal) {
    label:not(:first-of-type),
    .fmc-check__button:not(:first-of-type) {
      margin-top: var(--vertical-gap);
    }
  }

  // Label styling
  label > span,
  .fmc-check__button > label {
    --label-padding: #{fds-rem(4px)};
    padding: 0 var(--label-padding);
    margin: 0 0 0 calc(var(--button-label-gap) - var(--label-padding));
  }

  label,
  .fmc-check__button {
    display: inline-flex;
    align-items: flex-start;
    color: var(--text-color);
    line-height: var(--line-height);

    :hover {
      cursor: pointer;
    }
  }

  input[type="checkbox"],
  input[type="radio"] {
    // Button styling
    // Remove native radio/checkbox appearance
    appearance: none;
    -moz-appearance: none; /* stylelint-disable-line */
    -webkit-appearance: none; /* stylelint-disable-line */
    // ring / button outline
    width: var(--button-diameter);
    height: var(--button-diameter);
    border: solid var(--button-outline-thickness) var(--button-outline-color);
    background: var(--fill-color);
    margin: calc((var(--line-height) - var(--button-diameter)) / 2) 0 0 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex: none;

    @include fds-set-disabled {
      --fill-color: var(--button-fill-color-disabled);
      border-color: var(--disabled-color);
      cursor: not-allowed;

      + span,
      + label {
        color: var(--text-color-disabled);
        background-color: var(--text-background-color-disabled);
        cursor: not-allowed;
      }
    }

    &:checked {
      --button-outline-color: var(--checked-color);
      --fill-color: var(--button-fill-color-checked);
      --button-outline-thickness: var(--button-outline-thickness-checked);
    }
  }

  input[type="checkbox"]:focus-visible,
  input[type="radio"]:focus-visible {
    outline-style: solid;
    outline-width: 1px;
    outline-offset: 8px;
  }

  // Checkbox specific
  input[type="checkbox"] {
    border-radius: var(--check-radius);

    @include fds-set-brand(lincoln) {
      --button-fill-color-checked: var(--checked-color);
    }

    // checkmark
    &::before {
      @extend %fds-icons;
      content: $fds-font--ford-icons__tick;

      --icon-size: var(--check-size);
      color: var(--check-color);

      visibility: hidden;
    }

    &:checked::before {
      visibility: visible;
    }
  }

  // Radio Button Specific
  input[type="radio"] {
    border-radius: 100%;

    // dot inside
    &::before {
      content: "";
      width: var(--dot-diameter);
      height: var(--dot-diameter);
      border-radius: 100%;
      background-color: var(--dot-color);

      visibility: hidden;
    }

    &:checked::before {
      visibility: visible;
    }
  }
}
