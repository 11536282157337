.fmc-table-accordion {
  --button-font-size: #{fds-rem(11px)};
  --button-line-height: #{fds-rem(16px)};
  --title-font-size: #{fds-rem(16px)};
  --title-line-height: #{fds-rem(24px)};
  --title-letter-spacing: #{fds-rem(1px)};
  --title-text-transform: none;
  --panel-border: none;
  --button-padding: #{fds-rem(16px)};
  --button-text-color: var(--fds-color--white);
  --button-background-color: var(--fds-color--primary);
  --button-background-color-active: var(--fds-color--secondary);
  --body-background-color: var(--fds-color--white);
  --body-text-color: var(--fds-color--white);
  --expand-icon-color: inherit;
  --expand-icon-size: inherit;
  --expand-icon-symbol: '#{$fds-font--ford-icons__chevron-down}';
  --expand-icon-symbol--expanded: '#{$fds-font--ford-icons__chevron-down}';
  --expand-icon-transform: none;
  --expand-icon-transform--expanded: rotate(-180deg);
  --content-padding: #{fds-rem(16px)};
  --outline-color: var(--fds-color--white);
  --outline-offset: #{fds-rem(-10px)};
  --gap-sm: #{fds-rem(8px)};
  --gap-md: #{fds-rem(16px)};
  --transition: var(--fmc-transition-duration) var(--fmc-transition-timing);
  .fmc-table-accordion--chip-error1 {
    background-color: $fds-color--error1;
    border-radius: 2rem;
    width: 2rem;
    height: 2rem;
    font-size: 1.2rem;
    color: $fds-color--white;
    text-align: center;
  }
  .fmc-table-accordion__parent {
    border-bottom: 1.5px solid var(--fds-color--disabled2);
    + .fmc-table-accordion__child {
      
      height: 0;
      display: none;
      overflow: hidden;
      text-overflow: clip;
      background-color: var(--body-background-color);
      .fmc-table-accordion__content {
        visibility: hidden;
      }
      
      &.fmc-table-accordion--expanded {
        height: auto;
        display: table-row;
        .fmc-table-accordion__content {
          visibility: visible;
          border-bottom: 1.5px solid var(--fds-color--disabled2);
          tbody tr:not(:last-child) td {
            border-bottom: 1.5px solid var(--fds-color--disabled2);
          }
          tbody tr td {
            padding-top: 2rem;
            padding-bottom: 2rem;
          }
          table { 
            border-spacing: 0px;
          }
        }
        .fmc-table-accordion__content, 
        .fmc-table-accordion__content td {
          background-color: var(--fds-color--gray1);
        }
        .fmc-table-accordion__content th {
          background-color: var(--fds-color--gray1);
          color: var(--fds-color--primary);
          white-space: nowrap;
        }
        .fmc-table-accordion__content th,
        .fmc-table-accordion__content td {
          border: none;
        }
      }
  
  
    }
  }
}