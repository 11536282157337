.fmc-select {
  @extend %fmc-input;

  @supports not (appearance: none) {
    /* stylelint-disable property-no-vendor-prefix */
    -moz-appearance: none;
    -webkit-appearance: none;
    /* stylelint-enable property-no-vendor-prefix */
  }

  --padding-right: #{fds-rem(40px)};

  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTZweCIgaGVpZ2h0PSI4cHgiIHZpZXdCb3g9IjAgMCAxNiA4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1Ny4xICg4MzA4OCkgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+SWNvbnMgLyBDaGV2cm9uIC8gRG93bjwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJJY29ucy0vLUNoZXZyb24tLy1Eb3duIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8cGF0aCBkPSJNNC4xMzU4NTE0OSwxMS4xNTczNzQgQzMuOTQ2NjIzNSwxMS4zNTg0ODkzIDMuOTU2MjYwMSwxMS42NzQ5MjUxIDQuMTU3Mzc1NDQsMTEuODY0MTUzMSBDNC4zNTg0OTA3OCwxMi4wNTMzODExIDQuNjc0OTI2NTcsMTIuMDQzNzQ0NSA0Ljg2NDE1NDU2LDExLjg0MjYyOTIgTDExLjg2NDE1NDYsNC40MDI4ODc1MyBDMTIuMDQ0MjI3Myw0LjIxMTUwMjUzIDEyLjA0NTQyMjEsMy45MTMzODQzNyAxMS44NjY4ODkxLDMuNzIwNTYyMTggTDQuODY2ODg5MTUsLTMuODM5Njk2MTcgQzQuNjc5Mjc5MjUsLTQuMDQyMzIxNzggNC4zNjI5MzA4NywtNC4wNTQ0OTQ0MiA0LjE2MDMwNTI2LC0zLjg2Njg4NDUzIEMzLjk1NzY3OTY1LC0zLjY3OTI3NDYzIDMuOTQ1NTA3MDEsLTMuMzYyOTI2MjUgNC4xMzMxMTY5LC0zLjE2MDMwMDY0IEwxMC44MTYwNTQ3LDQuMDU3NTE4ODQgTDQuMTM1ODUxNDksMTEuMTU3Mzc0IFoiIGZpbGw9IiMxMDJCNEUiIGZpbGwtcnVsZT0ibm9uemVybyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoOC4wMDAwMDIsIDQuMDAwMDAxKSBzY2FsZSgxLCAtMSkgcm90YXRlKC05MC4wMDAwMDApIHRyYW5zbGF0ZSgtOC4wMDAwMDIsIC00LjAwMDAwMSkgIj48L3BhdGg+CiAgICA8L2c+Cjwvc3ZnPg==");

  @include fds-set-brand(lincoln) {
    background-position: top 2.5rem right 1.5rem; // prevent chevron for shifting on hover
  }

  @include fds-set-disabled {
    background-image: none;
  }
}
