@use "sass:math";

.fmc-floating-label {
  $growth_ratio: math.div(16, 11);
  $shrink_ratio: math.div(11, 16);

  --label-pos-x: #{fds-rem(17px)};
  --label-pos-y: #{fds-rem(41px)};
  --label-gap: #{fds-rem(8px)};
  --label-color: var(--fds-color--gray3);
  --label-text-font-size: #{fds-rem(16px)};
  --label-icon-font-size: #{fds-rem(16px)};
  --label-default-text-transform: scale(1) translate(var(--label-pos-x), var(--label-pos-y));
  --label-default-icon-transform: scale(1) translateY(0);
  --label-focus-text-transform: scale(#{$shrink_ratio}) translate(0);
  --label-focus-icon-transform: scale(#{$growth_ratio}) translateY(#{$growth_ratio * $growth_ratio * -1 + 'px'});
  --label-focus-text-gap: calc(var(--label-gap) * #{$growth_ratio});
  --label-transition-duration: var(--fmc-transition-duration);
  --label-transition-timing: ease-in-out;

  @extend %fmc-focus-wrapper;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--label-gap);

  label {
    line-height: 1;
  }

  input, textarea {
    order: 2;

    &::placeholder {
      color: transparent;
    }

    & ~ label {
      order: 1;
    }

    &:focus, &:not(:placeholder-shown) {
      & ~ label .fmc-floating-label__text {
        transform: var(--label-focus-text-transform);
        gap: var(--label-focus-text-gap);
        user-select: unset;

        &::after {
          transform: var(--label-focus-icon-transform);
        }
      }
    }

    &:not(:focus):placeholder-shown ~ label .fmc-floating-label__text {
      cursor: text;
    }
  }

  .fmc-floating-label__text {
    display: inline-flex;
    align-items: baseline;
    gap: var(--label-gap);
    color: var(--label-color);
    font-size: var(--label-text-font-size);
    transition: var(--label-transition-duration) var(--label-transition-timing);
    transform: var(--label-default-text-transform);
    user-select: none;
    max-width: calc(100% - 2 * var(--label-pos-x));
    white-space: nowrap;

    &, &::after {
      transform-origin: bottom left;
    }

    &::after {
      font-family: var(--fmc-font--ford-icons);
      font-size: var(--label-icon-font-size);
      line-height: 0;
      transition: transform var(--label-transition-duration) var(--label-transition-timing);
      transform: var(--label-default-icon-transform);
    }
  }

  &.fmc-floating-label--success .fmc-floating-label__text::after {
    content: var(--fds-font--ford-icons__success);
    color: var(--fds-color--success1);
  }

  &.fmc-floating-label--error .fmc-floating-label__text::after {
    content: var(--fds-font--ford-icons__error);
    color: var(--fds-color--error1);
  }

  &.fmc-floating-label--wrapped-input {
    label {
      display: flex;
      flex-direction: column;
      gap: var(--label-gap);
    }

    input, textarea {
      &:not(:focus):placeholder-shown ~ .fmc-floating-label__text {
        cursor: text;
      }
    }

    input:focus,
    textarea:focus,
    input:not(:placeholder-shown),
    textarea:not(:placeholder-shown) {
      & ~ .fmc-floating-label__text {
        transform: var(--label-focus-text-transform);
        gap: var(--label-focus-text-gap);

        &::after {
          transform: var(--label-focus-icon-transform);
        }
      }
    }

    .fmc-floating-label__text {
      order: 1;
    }
  }

  @include fds-set-brand(lincoln) {
    $shrink_ratio: math.div(13, 16);

    --label-color: var(--fds-color--primary);
    --label-pos-y: #{fds-rem(10px)};
    --label-pos-x: #{fds-rem(14px)};
    --label-default-text-transform: scale(1) translateY(#{fds-rem(12px)});
    --label-focus-text-transform: scale(calc(#{$shrink_ratio})) translateY(0);
    --label-icon-font-size: #{fds-rem(24px)};

    @keyframes fade {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    input, textarea {
      padding-top: #{fds-rem(20px)};
    }

    label {
      line-height: 0;
      cursor: text;

      &::after {
        position: absolute;
        top: 50%;
        right: var(--label-pos-x);
        font-family: var(--fmc-font--ford-icons);
        font-size: var(--label-icon-font-size);
        animation: fade forwards var(--label-transition-duration) var(--label-transition-timing);
        transition: color var(--label-transition-duration) var(--label-transition-timing);
      }
    }

    .fmc-floating-label__text {
      line-height: 1;
      transform-origin: left;
      max-width: 100%;

      &::after {
        content: unset;
      }
    }

    &.fmc-floating-label--success label {
      .fmc-floating-label__text, &::after {
        color: var(--fds-color--success1);
      }

      &::after {
        content: var(--fds-font--ford-icons__success);
      }
    }

    &.fmc-floating-label--error label {
      .fmc-floating-label__text, &::after {
        color: var(--fds-color--error1);
      }

      &::after {
        content: var(--fds-font--ford-icons__error);
      }
    }

    &:not(.fmc-floating-label--wrapped-input) {
      label {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        padding: var(--label-pos-y) var(--label-pos-x);
      }
    }

    &.fmc-floating-label--wrapped-input {
      .fmc-floating-label__text {
        position: absolute;
        top: var(--label-pos-y);
        left: var(--label-pos-x);
      }
    }
  }
}
