/*! name: @uvarov.frontend/vanilla-calendar | url: https://github.com/uvarov-frontend/vanilla-calendar */
// $fmc-calendar-picker-calendar-week__day_weekend--color: $fmc-calendar-picker-calendar-week__day_weekend--color;
// $fmc-calendar-picker-calendar-day__btn_selected--weekend-holiday: rgb(244 63 94 / var(--tw-bg-opacity));
$fmc-calendar-picker-calendar-day__btn_selected--weekend-holiday: $fds-color--secondary;
$fmc-calendar-picker-calendar-week__day_weekend--color: rgba($fds-color--secondary, 0.1);
[data-calendar-theme="light"].fmc-calendar-picker-calendar {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
[data-calendar-theme="light"].fmc-calendar-picker-calendar button:focus-visible {
    outline-color: #fdba74;
}
[data-calendar-theme="light"].fmc-calendar-picker-calendar.fmc-calendar-picker-calendar_multiple .fmc-calendar-picker-calendar-month,
[data-calendar-theme="light"].fmc-calendar-picker-calendar.fmc-calendar-picker-calendar_multiple .fmc-calendar-picker-calendar-year,
[data-calendar-theme="light"].fmc-calendar-picker-calendar.fmc-calendar-picker-calendar_multiple .fmc-calendar-picker-calendar-month:hover,
[data-calendar-theme="light"].fmc-calendar-picker-calendar.fmc-calendar-picker-calendar_multiple .fmc-calendar-picker-calendar-year:hover,
[data-calendar-theme="light"].fmc-calendar-picker-calendar.fmc-calendar-picker-calendar_multiple .fmc-calendar-picker-calendar-month.fmc-calendar-picker-calendar-month_disabled,
[data-calendar-theme="light"].fmc-calendar-picker-calendar.fmc-calendar-picker-calendar_multiple .fmc-calendar-picker-calendar-year.fmc-calendar-picker-calendar-year_disabled {
    color: #0f172a;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-arrow:before {
    --tw-bg-opacity: 1;
    background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-arrow:hover:before {
    --tw-bg-opacity: 1;
    background-color: rgb(100 116 139 / var(--tw-bg-opacity));
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-header__content,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-month,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-year {
    color: #0f172a;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-month:hover,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-year:hover {
    color: $fds-color--primary;
    // color: #64748b;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-month.fmc-calendar-picker-calendar-month_not-active,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-year.fmc-calendar-picker-calendar-year_not-active {
    color: #94a3b8;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-month.fmc-calendar-picker-calendar-month_disabled,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-year.fmc-calendar-picker-calendar-year_disabled {
    color: #cbd5e1;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-months__month {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    color: $fds-color--primary;
    // color: #64748b;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-months__month:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-months__month.fmc-calendar-picker-calendar-months__month_selected,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-months__month.fmc-calendar-picker-calendar-months__month_selected:hover {
    --tw-bg-opacity: 1;
    background-color: $fds-color--secondary;
    // background-color: rgb(6 182 212 / var(--tw-bg-opacity));
    color: $fds-color--white
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-months__month.fmc-calendar-picker-calendar-months__month_disabled,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-months__month.fmc-calendar-picker-calendar-months__month_disabled:hover {
    color: #cbd5e1;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-years__year {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    color: $fds-color--primary;
    // color: #64748b;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-years__year:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-years__year.fmc-calendar-picker-calendar-years__year_selected,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-years__year.fmc-calendar-picker-calendar-years__year_selected:hover {
    --tw-bg-opacity: 1;
    background-color: $fds-color--secondary;
    // background-color: rgb(6 182 212 / var(--tw-bg-opacity));
    color: $fds-color--white
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-years__year.fmc-calendar-picker-calendar-years__year_disabled,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-years__year.fmc-calendar-picker-calendar-years__year_disabled:hover {
    color: #cbd5e1;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-week-numbers__title,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-week-number {
    color: $fds-color--primary;
    // color: #64748b;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-week-number:hover {
    color: #475569;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-week__day {
    color: $fds-color--primary;
    // color: #64748b;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-week__day_weekend {
    color: $fmc-calendar-picker-calendar-week__day_weekend--color;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    color: #0f172a;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_today {
    color: $fds-color--secondary;
    // color: #06b6d4;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_today.fmc-calendar-picker-calendar-day__btn_prev,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_today.fmc-calendar-picker-calendar-day__btn_next {
    color: $fds-color--primary;
    // color: #64748b;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_prev,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_next {
    color: #94a3b8;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_disabled {
    color: #cbd5e1;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_hover {
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_weekend,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_holiday {
    color: $fmc-calendar-picker-calendar-week__day_weekend--color;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_weekend:hover,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_holiday:hover,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_weekend.fmc-calendar-picker-calendar-day__btn_hover,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_holiday.fmc-calendar-picker-calendar-day__btn_hover {
    --tw-bg-opacity: 1;
    // background-color: rgb(255 241 242 / var(--tw-bg-opacity));
    background-color: $fmc-calendar-picker-calendar-week__day_weekend--color;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_weekend.fmc-calendar-picker-calendar-day__btn_disabled,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_holiday.fmc-calendar-picker-calendar-day__btn_disabled {
    color: #cbd5e1;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_weekend.fmc-calendar-picker-calendar-day__btn_today,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_holiday.fmc-calendar-picker-calendar-day__btn_today {
    color: $fmc-calendar-picker-calendar-week__day_weekend--color;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_weekend.fmc-calendar-picker-calendar-day__btn_today.fmc-calendar-picker-calendar-day__btn_disabled,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_holiday.fmc-calendar-picker-calendar-day__btn_today.fmc-calendar-picker-calendar-day__btn_disabled {
    color: #cbd5e1;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_weekend.fmc-calendar-picker-calendar-day__btn_prev,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_holiday.fmc-calendar-picker-calendar-day__btn_prev,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_weekend.fmc-calendar-picker-calendar-day__btn_next,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_holiday.fmc-calendar-picker-calendar-day__btn_next {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    color: #94a3b8;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_weekend.fmc-calendar-picker-calendar-day__btn_prev:hover,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_holiday.fmc-calendar-picker-calendar-day__btn_prev:hover,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_weekend.fmc-calendar-picker-calendar-day__btn_next:hover,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_holiday.fmc-calendar-picker-calendar-day__btn_next:hover,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_weekend.fmc-calendar-picker-calendar-day__btn_prev.fmc-calendar-picker-calendar-day__btn_hover,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_holiday.fmc-calendar-picker-calendar-day__btn_prev.fmc-calendar-picker-calendar-day__btn_hover,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_weekend.fmc-calendar-picker-calendar-day__btn_next.fmc-calendar-picker-calendar-day__btn_hover,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_holiday.fmc-calendar-picker-calendar-day__btn_next.fmc-calendar-picker-calendar-day__btn_hover {
    --tw-bg-opacity: 1;
    background-color: $fmc-calendar-picker-calendar-week__day_weekend--color;
    // background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_weekend.fmc-calendar-picker-calendar-day__btn_prev.fmc-calendar-picker-calendar-day__btn_today,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_holiday.fmc-calendar-picker-calendar-day__btn_prev.fmc-calendar-picker-calendar-day__btn_today,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_weekend.fmc-calendar-picker-calendar-day__btn_next.fmc-calendar-picker-calendar-day__btn_today,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_holiday.fmc-calendar-picker-calendar-day__btn_next.fmc-calendar-picker-calendar-day__btn_today {
    color: #94a3b8;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_weekend.fmc-calendar-picker-calendar-day__btn_prev.fmc-calendar-picker-calendar-day__btn_disabled,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_holiday.fmc-calendar-picker-calendar-day__btn_prev.fmc-calendar-picker-calendar-day__btn_disabled,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_weekend.fmc-calendar-picker-calendar-day__btn_next.fmc-calendar-picker-calendar-day__btn_disabled,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_holiday.fmc-calendar-picker-calendar-day__btn_next.fmc-calendar-picker-calendar-day__btn_disabled {
    color: #cbd5e1;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_weekend.fmc-calendar-picker-calendar-day__btn_selected,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_holiday.fmc-calendar-picker-calendar-day__btn_selected,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_weekend.fmc-calendar-picker-calendar-day__btn_selected:hover,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_holiday.fmc-calendar-picker-calendar-day__btn_selected:hover {
    --tw-bg-opacity: 1;
    background-color: $fmc-calendar-picker-calendar-day__btn_selected--weekend-holiday;
    color: $fds-color--white
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_weekend.fmc-calendar-picker-calendar-day__btn_selected.fmc-calendar-picker-calendar-day__btn_intermediate,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_holiday.fmc-calendar-picker-calendar-day__btn_selected.fmc-calendar-picker-calendar-day__btn_intermediate,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_weekend.fmc-calendar-picker-calendar-day__btn_selected.fmc-calendar-picker-calendar-day__btn_intermediate:hover,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_holiday.fmc-calendar-picker-calendar-day__btn_selected.fmc-calendar-picker-calendar-day__btn_intermediate:hover {
    background-color: $fmc-calendar-picker-calendar-day__btn_selected--weekend-holiday;
    --tw-bg-opacity: 0.7;
    color: $fds-color--white
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_weekend.fmc-calendar-picker-calendar-day__btn_prev.fmc-calendar-picker-calendar-day__btn_selected,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_holiday.fmc-calendar-picker-calendar-day__btn_prev.fmc-calendar-picker-calendar-day__btn_selected,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_weekend.fmc-calendar-picker-calendar-day__btn_next.fmc-calendar-picker-calendar-day__btn_selected,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_holiday.fmc-calendar-picker-calendar-day__btn_next.fmc-calendar-picker-calendar-day__btn_selected,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_weekend.fmc-calendar-picker-calendar-day__btn_prev.fmc-calendar-picker-calendar-day__btn_selected:hover,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_holiday.fmc-calendar-picker-calendar-day__btn_prev.fmc-calendar-picker-calendar-day__btn_selected:hover,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_weekend.fmc-calendar-picker-calendar-day__btn_next.fmc-calendar-picker-calendar-day__btn_selected:hover,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_holiday.fmc-calendar-picker-calendar-day__btn_next.fmc-calendar-picker-calendar-day__btn_selected:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(203 213 225 / var(--tw-bg-opacity));
    color: $fds-color--primary;
    // color: #64748b;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_weekend.fmc-calendar-picker-calendar-day__btn_prev.fmc-calendar-picker-calendar-day__btn_selected.fmc-calendar-picker-calendar-day__btn_intermediate,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_holiday.fmc-calendar-picker-calendar-day__btn_prev.fmc-calendar-picker-calendar-day__btn_selected.fmc-calendar-picker-calendar-day__btn_intermediate,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_weekend.fmc-calendar-picker-calendar-day__btn_next.fmc-calendar-picker-calendar-day__btn_selected.fmc-calendar-picker-calendar-day__btn_intermediate,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_holiday.fmc-calendar-picker-calendar-day__btn_next.fmc-calendar-picker-calendar-day__btn_selected.fmc-calendar-picker-calendar-day__btn_intermediate,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_weekend.fmc-calendar-picker-calendar-day__btn_prev.fmc-calendar-picker-calendar-day__btn_selected.fmc-calendar-picker-calendar-day__btn_intermediate:hover,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_holiday.fmc-calendar-picker-calendar-day__btn_prev.fmc-calendar-picker-calendar-day__btn_selected.fmc-calendar-picker-calendar-day__btn_intermediate:hover,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_weekend.fmc-calendar-picker-calendar-day__btn_next.fmc-calendar-picker-calendar-day__btn_selected.fmc-calendar-picker-calendar-day__btn_intermediate:hover,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_holiday.fmc-calendar-picker-calendar-day__btn_next.fmc-calendar-picker-calendar-day__btn_selected.fmc-calendar-picker-calendar-day__btn_intermediate:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(226 232 240 / var(--tw-bg-opacity));
    color: $fds-color--primary;
    // color: #64748b;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_selected,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_selected:hover {
    --tw-bg-opacity: 1;
    background-color: $fds-color--secondary;
    // background-color: rgb(6 182 212 / var(--tw-bg-opacity));
    color: $fds-color--white
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_selected.fmc-calendar-picker-calendar-day__btn_intermediate,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_selected.fmc-calendar-picker-calendar-day__btn_intermediate:hover {
    background-color: $fds-color--secondary;
    // background-color: rgb(6 182 212 / var(--tw-bg-opacity));
    --tw-bg-opacity: 0.7;
    color: $fds-color--white
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_selected.fmc-calendar-picker-calendar-day__btn_prev,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_selected.fmc-calendar-picker-calendar-day__btn_next,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_selected.fmc-calendar-picker-calendar-day__btn_prev:hover,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_selected.fmc-calendar-picker-calendar-day__btn_next:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(203 213 225 / var(--tw-bg-opacity));
    color: $fds-color--primary;
    // color: #64748b;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_selected.fmc-calendar-picker-calendar-day__btn_prev.fmc-calendar-picker-calendar-day__btn_intermediate,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_selected.fmc-calendar-picker-calendar-day__btn_next.fmc-calendar-picker-calendar-day__btn_intermediate,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_selected.fmc-calendar-picker-calendar-day__btn_prev.fmc-calendar-picker-calendar-day__btn_intermediate:hover,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__btn_selected.fmc-calendar-picker-calendar-day__btn_next.fmc-calendar-picker-calendar-day__btn_intermediate:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(226 232 240 / var(--tw-bg-opacity));
    color: $fds-color--primary;
    // color: #64748b;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__popup {
    color: #0f172a;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__popup:before {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    --tw-shadow: 0 3px 15px rgba(85, 85, 85, 0.2);
    --tw-shadow-colored: 0 3px 15px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-day__popup:after {
    border-bottom-color: $fds-color--white
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-time {
    border-color: #cbd5e1;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-time__hours:after {
    content: var(--tw-content);
    color: #0f172a;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-time__hours input,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-time__minutes input {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    color: #0f172a;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-time__hours input:hover,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-time__minutes input:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(255 237 213 / var(--tw-bg-opacity));
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-time__hours input:focus-visible,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-time__minutes input:focus-visible {
    outline-color: #fdba74;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-time__hours input.fmc-calendar-picker-calendar-is-focus,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-time__minutes input.fmc-calendar-picker-calendar-is-focus {
    --tw-bg-opacity: 1;
    background-color: rgb(255 237 213 / var(--tw-bg-opacity));
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-time__keeping {
    color: $fds-color--primary;
    // color: #64748b;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-time__keeping:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(255 237 213 / var(--tw-bg-opacity));
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-time__keeping:focus-visible {
    outline-color: #fdba74;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-time__range input {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-time__range:before,
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-time__range:after {
    --tw-bg-opacity: 1;
    background-color: rgb(203 213 225 / var(--tw-bg-opacity));
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-time__range:hover input::-webkit-slider-thumb {
    border-color: #94a3b8;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-time__range:hover input::-moz-range-thumb {
    border-color: #94a3b8;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-time__range input:focus-visible::-webkit-slider-thumb {
    border-color: #fdba74;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-time__range input:focus-visible::-moz-range-thumb {
    border-color: #fdba74;
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-time__range input::-webkit-slider-thumb {
    border-color: #cbd5e1;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-time__range input::-moz-range-thumb {
    border-color: #cbd5e1;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-time__range input::-webkit-slider-runnable-track {
    --tw-bg-opacity: 1;
    background-color: rgb(203 213 225 / var(--tw-bg-opacity));
}
[data-calendar-theme="light"] .fmc-calendar-picker-calendar-time__range input::-moz-range-track {
    --tw-bg-opacity: 1;
    background-color: rgb(203 213 225 / var(--tw-bg-opacity));
}
