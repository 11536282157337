.fmc-button {
  --color-disabled: var(--fds-color--disabled3);
  --color-disabled-dark: var(--fds-color--disabled1);
  --color-disabled-outlined-dark: var(--fds-color--disabled2);
  --bg-color: var(--fds-color--primary);
  --bg-color-dark: var(--fds-color--white);
  --bg-color-hover: var(--fds-color--secondary);
  --bg-color-dark-hover: var(--fds-color--gray1);
  --bg-color-active: var(--fds-color--secondary);
  --bg-color-dark-active: var(--fds-color--gray1);
  --bg-color-outlined: unset;
  --bg-color-outlined-hover: var(--fds-color--primary-5);
  --bg-color-outlined-active: var(--fds-color--primary-5);
  --bg-color-outlined-dark: unset;
  --bg-color-outlined-dark-hover: var(--fds-color--white-10);
  --bg-color-outlined-dark-active: var(--fds-color--white-10);
  --border-width: 0.1rem;
  --border-color: transparent;
  --border-color-hover: transparent;
  --border-color-outlined: var(--fds-color--primary);
  --border-color-outlined-hover: var(--fds-color--secondary);
  --border-color-outlined-dark: var(--fds-color--white);
  --border-accent: transparent;
  --border-accent-hover: transparent;
  --height: 4rem;
  --padding: 0 3.2rem;
  --radius: 2rem;
  --shadow: #{$fds-elevation__box-shadow--layer2};
  --shadow-hover: #{$fds-elevation__box-shadow--layer3};
  --shadow-active: #{$fds-elevation__box-shadow--layer1};
  --scale: 1;
  --scale-hover: 1.07;
  --scale-active: 1;
  --text-color: var(--fds-color--white);
  --text-color-disabled: var(--fds-color--white);
  --text-color-disabled-dark: var(--fds-color--black);
  --text-color-hover: var(--text-color);
  --text-color-dark: var(--fds-color--primary);
  --text-color-outlined: var(--fds-color--primary);
  --text-color-outlined-hover: var(--fds-color--secondary);
  --text-color-outlined-dark: var(--fds-color--white);
  --text-color-outlined-dark-hover: var(--fds-color--white);
  --text-line-height: #{$fds-line-height__primary-button--sm};
  --text-spacing: #{$fds-letter-spacing__primary-button--sm};
  --text-size: #{fds-rem(18px)};
  --icon-gap: 1.6rem;
  --icon-offset: 0;
  --button-icon-size: #{fds-rem(16px)};
  --icon-weight: normal;
  --cursor: pointer;
  --outline: none;

  @include fds-set-brand(lincoln) {
    --color-disabled: var(--fds-color--gray4);
    --color-disabled-dark: var(--fds-color--gray4);
    --color-disabled-outlined-dark: var(--fds-color--gray4);
    --text-color-disabled-dark: var(--fds-color--white);
    --bg-color-hover: var(--fds-color--primary);
    --bg-color-dark-hover: var(--fds-color--white);
    --bg-color-active: var(--fds-color--black);
    --bg-color-dark-active: var(--fds-color--disabled2);
    --bg-color-outlined-hover: var(--fds-color--primary);
    --bg-color-outlined-active: var(--fds-color--black);
    --bg-color-outlined-dark: unset;
    --bg-color-outlined-dark-hover: var(--fds-color--white);
    --bg-color-outlined-dark-active: var(--fds-color--disabled2);
    --border-color-outlined-hover: transparent;
    --border-accent-hover: var(--fds-color--secondary);
    --border-width: 0.2rem;
    --height: 5rem;
    --padding: 0 2rem;
    --radius: unset;
    --scale-hover: 1;
    --shadow: none;
    --shadow-hover: none;
    --shadow-active: none;
    --text-color-outlined-hover: var(--fds-color--white);
    --text-color-outlined-dark-hover: var(--fds-color--primary);
    --text-size: #{fds-rem(16px)};
    --icon-gap: 0.8rem;
    --icon-offset: -0.5rem;
    --button-icon-size: #{fds-rem(24px)};
    --icon-weight: normal;
  }

  font-size: var(--text-size);
  font-stretch: condensed;
  line-height: var(--text-line-height);
  letter-spacing: var(--text-spacing);
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  background: none;
  border: none;
  padding: var(--padding);
  height: var(--height);
  position: relative;
  color: var(--text-color);
  z-index: 1;
  outline: var(--outline);
  outline-offset: var(--fds-outline-offset);
  cursor: var(--cursor);

  &:link {
    color: var(--text-color);
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: scale(var(--scale));
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out,
      background-color 0.3s linear, border-color 0.3s linear;
  }

  // accent
  &::before {
    display: none;
    border-bottom: 2px solid var(--border-accent);

    @include fds-set-brand(lincoln) {
      display: block;
    }
  }

  // border
  &::after {
    border-radius: var(--radius);
    border: var(--border-width) solid var(--border-color);
    background-color: var(--bg-color);
    z-index: -1;
  }

  &:not(.fmc-button--no-shadow)::after {
    box-shadow: var(--shadow);
  }

  .fds-icon {
    font-size: var(--button-icon-size);

    &::before {
      color: inherit;
    }
  }

  // base dark styles
  @include fds-set-dark {
    --text-color: var(--text-color-dark);
    --bg-color: var(--bg-color-dark);
    --bg-color-hover: var(--bg-color-dark-hover);
    --bg-color-active: var(--bg-color-dark-active);
  }

  // outlined
  &.fmc-button--outlined {
    --text-color: var(--text-color-outlined);
    --text-color-hover: var(--text-color-outlined-hover);
    --bg-color: var(--bg-color-outlined);
    --bg-color-hover: var(--bg-color-outlined-hover);
    --bg-color-active: var(--bg-color-outlined-active);
    --border-color: var(--border-color-outlined);
    --border-color-hover: var(--border-color-outlined-hover);
    --shadow: none;
    --shadow-hover: none;
    --shadow-active: none;

    &:focus:not(:focus-visible) {
      --bg-color: var(--bg-color-outlined);
    }

    // dark styles for outlined
    @include fds-set-dark {
      --bg-color: var(--bg-color-outlined-dark);
      --bg-color-hover: var(--bg-color-outlined-dark-hover);
      --bg-color-active: var(--bg-color-outlined-dark-active);
      --border-color: var(--border-color-outlined-dark);
      --text-color: var(--text-color-outlined-dark);
      --text-color-hover: var(--text-color-outlined-dark-hover);
    }
  }

  [class*="fds-font--ford-icons__chevron"] {
    font-weight: var(--icon-weight);
  }

  /* Focus, Hover, Active variants */


  // set focus styles for all browsers
  &:focus {
    --scale: var(--scale-hover);
    --shadow: var(--shadow-hover);
    --border-accent: var(--border-accent-hover);
    --bg-color: var(--bg-color-hover);
    --outline: var(--fds-outline);
    outline: var(--outline); // necessary specificity to override global :focus
  }

  // reset focus styles for browsers that support focus-visible
  &:focus:not(:focus-visible) {
    --scale: unset;
    --shadow: var(--shadow-hover);
    --bg-color: var(--fds-color--primary);
    --border-accent: transparent;
    --outline: none;

    &:hover {
      --scale: var(--scale-hover);
      --border-accent: var(--border-accent-hover);
    }
  }

  // set new focus-visible styles
  &:focus-visible {
    --outline: var(--fds-outline);
    outline: var(--outline); // necessary specificity to override global :focus
  }

  @include fds-set-dark {
    &:focus {
      --bg-color: var(--bg-color-dark);
      --outline: var(--fds-outline-dark);
    }

    &:focus:not(:focus-visible) {
      --outline: none;
    }

    &:focus-visible {
      --outline: var(--fds-outline-dark);
    }
  }

  // hover states
  @include fds-set-hover {
    --scale: var(--scale-hover);
    --shadow: var(--shadow-hover);
    --border-color: var(--border-color-hover);
    --border-accent: var(--border-accent-hover);

    color: var(--text-color-hover);

    &::after {
      --bg-color: var(--bg-color-hover);
    }
  }

  // active states
  &:active {
    &::after {
      --scale: var(--scale-active);
      --shadow: var(--shadow-active);
      --bg-color: var(--bg-color-active);
    }
  }

  // disabled states
  @include fds-set-disabled {
    --bg-color: var(--color-disabled);
    --bg-color-hover: var(--color-disabled);
    --bg-color-active: var(--color-disabled);
    --border-accent: transparent;
    --shadow: none;
    --shadow-hover: none;
    --shadow-active: none;
    --scale: var(--scale);
    --cursor: not-allowed;

    // disabled state for base dark
    @include fds-set-dark {
      --text-color: var(--text-color-disabled-dark);
      --bg-color: var(--color-disabled-dark);
      --bg-color-hover: var(--color-disabled-dark);
      --bg-color-active: var(--color-disabled-dark);
    }

    // disable dstate for outlined
    &.fmc-button--outlined {
      --bg-color: unset;
      --bg-color-hover: unset;
      --bg-color-active: unset;
      --text-color: var(--color-disabled);
      --text-color-hover: var(--color-disabled);
      --border-color: var(--color-disabled);

      // dark styles with disabled state
      @include fds-set-dark {
        --text-color: var(--color-disabled-outlined-dark);
        --text-color-hover: var(--color-disabled-outlined-dark);
        --border-color: var(--color-disabled-outlined-dark);
      }
    }
  }
}
