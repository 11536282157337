.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
textarea.fmc-input::placeholder {
	color: #4d4d4d;
}

.MeQ-percent {
	text-transform: lowercase;
}

.preferencesBadge {
	margin-left: 4px;
}

.no-text-transform {
	text-transform: none !important;
}

.v1.hidden-battery-percentage {
	font-size: 5rem;
	font-weight: 700;
	margin-bottom: 2rem;
}

.v1.hidden-battery-text {
	font-size: 2.2rem;
	padding-left: 0.5rem;
}

.dashTabContainer {
	overflow: visible !important;
}

.fds-font--color__info {
	color: #066fef;
}

.fds-b--b-1 {
	border-bottom: 1px solid #00095b;
}

.dash-table.charging-infra-table td:first-child {
	width: 75%;
}

.dash-table.charging-infra-table th:nth-child(2),
.dash-table.charging-infra-table td:nth-child(2) {
	width: 25%;
	text-align: center;
	display: table-cell;
}

.dash-panel-font-blue {
	color: #066fef;
}

.dealerAlign {
	display: flex;
	align-items: center;
	justify-content: end;
}

.dealer-info {
	min-width: unset;
}
