.fmc-input {
  @extend %fmc-input;

  &[type="date"],
  &[type="time"] {
    display: flex;
    align-items: center;
  }
}

.fmc-input--on-dark {
  @extend %fmc-input--on-dark;
}

.fmc-input--success {
  --border-color: var(--fds-color--success1) !important;
  --border-bottom-weight: 0.2rem;
}

.fmc-input--error {
  --border-color: var(--fds-color--error1) !important;
  --border-bottom-weight: 0.2rem;
}

.fmc-label {
  display: inline-block;
  margin-bottom: 1rem;
}
