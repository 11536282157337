.fmc-tooltip {
  --color: var(--fds-color--gray3);
  --background-color: var(--fds-color--white);
  --border-size: #{fds-rem(1px)};
  --border-color: var(--fds-color--gray3);
  --box-shadow: var(--fmc-elevation__box-shadow--layer2);
  --padding: #{fds-rem(16px)};
  --pointer-size: #{fds-rem(10px)};
  --pointer-gap: calc(var(--pointer-size) + #{fds-rem(16px)});
  --focus-color: var(--fds-color--primary);

  @include fds-set-brand(lincoln) {
    --color: var(--fds-color--white);
    --background-color: var(--fds-color--primary);
    --border-color: var(--fds-color--primary);
    --box-shadow: none;
    --padding: #{fds-rem(13px)};
    --pointer-gap: calc(var(--pointer-size) + #{fds-rem(13px)});
    --focus-color: var(--fds-color--white);
  }

  position: relative;
  display: inline-flex;

  p {
    color: var(--color);
    padding: fds-rem(10px) fds-rem(2px);
  }

  a {
    @include fds-set-brand(lincoln) {
      color: var(--fds-color--white);
    }
  }

  a:focus {
    outline-offset: 1px;

    @include fds-set-brand(lincoln) {
      outline-color: var(--focus-color);
    }
  }

  &.fmc-tooltip--light {
    @include fds-set-brand(lincoln) {
      --color: var(--fds-color--primary);
      --background-color: var(--fds-color--white);
      --border-color: var(--fds-color--disabled2);
    }
  }

  &.fmc-tooltip--gray {
    @include fds-set-brand(lincoln) {
      --color: var(--fds-color--primary);
      --background-color: var(--fds-color--gray1);
      --border-color: var(--fds-color--disabled2);
    }
  }

  // trigger
  .fmc-tooltip__trigger {
    display: inline-flex;
  }

  .fmc-tooltip__trigger:focus-visible {
    outline-offset: fds-rem(4px);
    outline: 1px solid var(--fds-color--primary);
  }

  // wrapper
  .fmc-tooltip__wrapper {
    position: absolute;
    z-index: 2;
    opacity: 0;
    pointer-events: none;
    transition: transform var(--fmc-transition-duration) var(--fmc-transition-timing), opacity var(--fmc-transition-duration) var(--fmc-transition-timing);
  }

  &.fmc-tooltip--opened .fmc-tooltip__wrapper {
    opacity: 1;
    pointer-events: auto;
  }

  &.fmc-tooltip--top .fmc-tooltip__wrapper {
    left: 50%;
    bottom: calc(100% + var(--pointer-gap));
    transform: translateX(-50%) translateY(fds-rem(8px));
  }

  &.fmc-tooltip--opened.fmc-tooltip--top .fmc-tooltip__wrapper {
    transform: translateX(-50%) translateY(fds-rem(0));
  }

  &.fmc-tooltip--right .fmc-tooltip__wrapper {
    top: 50%;
    left: calc(100% + var(--pointer-gap));
    transform: translateY(-50%) translateX(-8px);
  }

  &.fmc-tooltip--opened.fmc-tooltip--right .fmc-tooltip__wrapper {
    transform: translateY(-50%) translateX(0);
  }

  &.fmc-tooltip--bottom .fmc-tooltip__wrapper {
    left: 50%;
    top: calc(100% + var(--pointer-gap));
    transform: translateX(-50%) translateY(-8px);
  }

  &.fmc-tooltip--opened.fmc-tooltip--bottom .fmc-tooltip__wrapper {
    transform: translateX(-50%) translateY(0);
  }

  &.fmc-tooltip--left .fmc-tooltip__wrapper {
    top: 50%;
    right: calc(100% + var(--pointer-gap));
    transform: translateY(-50%) translateX(8px);
  }

  &.fmc-tooltip--opened.fmc-tooltip--left .fmc-tooltip__wrapper {
    transform: translateY(-50%) translateX(0);
  }

  // overlay
  .fmc-tooltip__overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100vh;
  }

  &.fmc-tooltip--opened .fmc-tooltip__overlay {
    display: block;
  }

  // content
  .fmc-tooltip__content {
    color: var(--color);
    display: flex;
    align-items: center;
    position: relative;
    transition: opacity var(--fmc-transition-duration) var(--fmc-transition-timing), transform var(--fmc-transition-duration) var(--fmc-transition-timing);
    width: 100%;
    max-width: fds-rem(304px);
    white-space: nowrap;
    height: fds-rem(48px);
    padding: var(--padding);
    background-color: var(--background-color);
    border: var(--border-size) solid var(--border-color);
    border-radius: fds-rem(3px);
    box-shadow: var(--box-shadow);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .fmc-tooltip__content--measure {
    position: fixed !important;
    width: auto !important;
    top: fds-rem(-9999px) !important;
  }

  // pointer
  .fmc-tooltip__pointer {
    position: absolute;
    z-index: 116;
    transition: opacity var(--fmc-transition-duration) var(--fmc-transition-timing), transform var(--fmc-transition-duration) var(--fmc-transition-timing);
  }

  .fmc-tooltip__pointer::after,
  .fmc-tooltip__pointer::before {
    border: solid transparent;
    border-color: transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .fmc-tooltip__pointer::after {
    border-top-color: var(--background-color);
    border-width: var(--pointer-size);
    margin-left: calc(var(--pointer-size) * -1);
  }

  .fmc-tooltip__pointer::before {
    border-top-color: var(--border-color);
    border-width: calc(var(--pointer-size) + var(--border-size));
    margin-left: calc((var(--pointer-size) + var(--border-size)) * -1);
  }

  &.fmc-tooltip--top .fmc-tooltip__pointer {
    top: 100%;
    left: 50%;
    transform: translateY(calc(var(--border-size) * -1));
  }

  &.fmc-tooltip--right .fmc-tooltip__pointer {
    right: 100%;
    top: 50%;
    transform: rotate(90deg) translateY(calc(var(--border-size) * -1));
  }

  &.fmc-tooltip--bottom .fmc-tooltip__pointer {
    bottom: 100%;
    left: 50%;
    transform: rotate(180deg) translateY(calc(var(--border-size) * -1));
  }

  &.fmc-tooltip--left .fmc-tooltip__pointer {
    left: 100%;
    top: 50%;
    transform: rotate(270deg) translateY(calc(var(--border-size) * -1));
  }

  .fmc-tooltip__pointer-fix {
    width: 1px;
    height: calc(var(--pointer-size) * 2);
    display: inline-block;
    position: absolute;
    top: calc(var(--pointer-size) * -1);

    // right: -0.5px;
    right: calc(var(--border-size) / 2);
    z-index: 1;
    transform: rotate(90deg);
    background: var(--background-color);
  }

  // popup tip
  &.fmc-tooltip--popup {
    --close-btn-size: 2.4rem;

    @include fds-set-brand(lincoln) {
      --close-btn-size: 1.6rem;
    }

    .fmc-tooltip__wrapper {
      width: fds-rem(296px);
    }

    .fmc-tooltip__content {
      white-space: normal;
      height: auto;
      display: block;
    }

    .fmc-tooltip__close {
      position: absolute;
      top: fds-rem(16px);
      right: fds-rem(16px);
      font-size: var(--close-btn-size);

      @include fds-set-brand(lincoln) {
        right: 1rem;
      }
    }

    .fmc-tooltip__close:focus-visible {
      outline-offset: 1px;
      outline: 1px solid var(--focus-color);
    }

    .fmc-tooltip__header {
      margin-bottom: fds-rem(16px);
      padding: 0 fds-rem(2px);
    }

    .fmc-tooltip__body {
      max-height: 80vh;
      overflow-y: auto;
      padding-right: 1.6rem;

      @include fds-set-brand(lincoln) {
        &::-webkit-scrollbar {
          width: 8px;
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 8px;
          background: var(--fds-color--black);
        }
      }
    }
  }

  // overflow menu
  &.fmc-tooltip--overflow-menu {
    --padding: 0;

    .fmc-tooltip__content {
      height: auto;
    }
  }

  // mobile
  &.fmc-tooltip--mobile {
    .fmc-tooltip__wrapper {
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      position: fixed;
      transform: none !important;
    }

    .fmc-tooltip__content {
      height: 100%;
      border: 0;
      max-width: none;
    }

    .fmc-tooltip__pointer {
      display: none;
    }
  }
}
