.fmc-text-button {
  --text-color: var(--fds-color--primary);
  --text-color-hover: var(--fds-color--secondary);
  --text-color-disabled: var(--fds-color--disabled3);
  --text-decoration: none;
  --font-weight: inherit;
  --letter-spacing: #{$fds-letter-spacing__secondary-button--sm};
  --outline: none;
  --cursor: pointer;
  --button-icon-offset: 1.6rem;
  --button-icon-size: 1.6rem;
  --underline-weight: 0.1rem;
  --underline-offset: 0.3rem;
  --underline-color: var(--fds-color--secondary);
  --underline-color-dark: var(--fds-color--white);
  --height: #{fds-rem(40px)};

  @include fds-set-brand(lincoln) {
    --underline-weight: 0.2rem;
    --underline-offset: 0.8rem;
    --text-color-hover: var(--fds-color--primary);
    --text-color-disabled: var(--fds-color--gray4);
    --underline-color-dark: var(--fds-color--secondary);
    --letter-spacing: 0;
    --font-weight: 500;
    --button-icon-size: 2.4rem;
    --button-icon-offset: 0.8rem;
  }

  display: inline-block;
  font-size: $fds-font-size__secondary-button--sm;
  font-stretch: condensed;
  font-weight: var(--font-weight);
  line-height: var(--height);
  letter-spacing: var(--letter-spacing);
  align-items: center;
  color: var(--text-color);
  text-decoration: var(--text-decoration);
  text-underline-offset: var(--underline-offset);
  outline: var(--outline);
  outline-offset: var(--fds-outline-offset);
  cursor: var(--cursor);
  text-decoration-thickness: var(--underline-weight); /* stylelint-disable property-no-unknown */
  text-decoration-color: var(--underline-color);
  position: relative;

  &:link {
    color: var(--text-color);
  }

  // set focus styles for all browsers
  &:focus {
    --outline: var(--fds-outline);
    --text-color: var(--text-color-hover);
    --text-decoration: underline;
    outline: var(--outline); // necessary specificity to override global :focus
  }

  // reset focus styles for browsers that support focus-visible
  &:focus:not(:focus-visible) {
    --text-color: var(--fds-color--primary);
    --text-decoration: none;
    --outline: none;

    &:hover {
      --text-decoration: underline;
      --text-color: var(--text-color-hover);
    }
  }

  // set new focus-visible styles
  &:focus-visible {
    --outline: var(--fds-outline);
    outline: var(--outline); // necessary specificity to override global :focus
  }

  @include fds-set-hover {
    --text-color: var(--text-color-hover);
    --text-decoration: underline;
  }

  @include fds-set-dark {
    --text-color: var(--fds-color--white);
    --underline-color: var(--underline-color-dark);

    &:focus {
      --text-color: var(--fds-color--white);
      --outline: var(--fds-outline-dark);
    }

    &:focus:not(:focus-visible) {
      --outline: none;

      &:hover {
        --text-color: var(--fds-color--white);
      }
    }

    &:focus-visible {
      --outline: var(--fds-outline-dark);
    }
  }

  @include fds-set-disabled {
    --text-color: var(--text-color-disabled);
    --cursor: default;
    --text-decoration: none;

    @include fds-set-dark {
      --text-color: var(--fds-color--disabled2);
    }
  }

  .fds-icon {
    font-size: var(--button-icon-size);
    display: inline-block;
    vertical-align: middle;
  }

  .fds-icon::before {
    color: var(--text-color);
  }

  .fds-icon--offset-left,
  .fds-icon--offset-right {
    --offset: var(--button-icon-offset);
  }

  // The rulesets below will be deprecated; instead of adding a class to the
  // text-button, simply add an icon (like is currently done for fmc-button.)
  .fmc-text-button__chevron {
    font-size: var(--button-icon-size);
  }

  &.fmc-text-button--chevron-right::after,
  &.fmc-text-button--chevron-left::before {
    @extend %fds-icons;

    font-size: var(--button-icon-size);
    display: inline-block;
    vertical-align: middle;
  }

  &.fmc-text-button--chevron-right::after {
    margin-left: var(--button-icon-offset);
    content: $fds-font--ford-icons__chevron-filled-right;

    @include fds-set-brand(lincoln) {
      content: $fds-font--ford-icons__chevron-right;
    }
  }

  &.fmc-text-button--chevron-left::before {
    margin-right: var(--button-icon-offset);
    content: $fds-font--ford-icons__chevron-filled-left;

    @include fds-set-brand(lincoln) {
      content: $fds-font--ford-icons__chevron-left;
    }
  }
}
