%fmc-input {
  --shadow: none;
  --shadow-hover: #{$fds-elevation__box-shadow--layer3};
  --background-color: transparent;
  --background-color-focus: var(--fds-color--white);
  --border-color: var(--fds-color--gray2);
  --text-color: var(--fds-color--primary);
  --text-color-disabled: var(--fds-color--white);
  --placeholder-text-color: var(--fds-color--gray3);
  --placeholder-letter-spacing: #{fds-rem(1px)};
  --border-weight: 0.1rem;
  --border-bottom-weight: 0.1rem;
  --border-bottom-weight-focus: 0.2rem;
  --border-all: 0.1rem solid var(--border-color);
  --border-bottom: var(--border-all);
  --border-bottom-focus: var(--border-all);
  --border-radius: 0.3rem;
  --height: #{fds-rem(48px)};
  --padding-left: #{fds-rem(16px)};
  --padding-top: 0;
  --padding-right: var(--padding-left);
  --padding-bottom: 0;
  --border-color-on-dark: var(--fds-color--white);
  --text-color-on-dark: var(--fds-color--white);
  --placeholder-text-color-on-dark: var(--fds-color--white);
  --border-color-on-dark-focus: var(--fds-color--disabled2);
  --text-color-on-dark-focus: var(--fds-color--primary);
  --placeholder-text-color-on-dark-focus: var(--fds-color--gray3);

  @include fds-set-brand(lincoln) {
    --border-color: var(--fds-color--primary);
    --border-color-focus: var(--fds-color--secondary);
    --border-radius: 0;
    --border-all: none;
    --border-bottom: var(--border-bottom-weight) solid var(--border-color);
    --background-color: var(--fds-color--gray2);
    --background-color-focus: var(--fds-color--gray2);
    --border-bottom-focus: var(--border-bottom-weight) solid var(--border-color-focus);
    --shadow-hover: none;
    --height: 6rem;
    --padding-left: #{fds-rem(14px)};
    --padding-top: #{fds-rem(1px)};  // prevents shifting text on lincoln focus
    --placeholder-text-color: var(--fds-color--primary);
    --placeholder-letter-spacing: 0;
  }

  display: block;
  width: 100%;
  height: var(--height);
  padding: var(--padding-top) var(--padding-right) var(--padding-bottom) var(--padding-left);
  font-size: 1.6rem;
  color: var(--text-color);
  border: var(--border-all);
  border-bottom: var(--border-bottom);
  border-radius: var(--border-radius);
  background-color: var(--background-color);
  background-repeat: no-repeat;
  background-position: right 1.5rem center;
  background-size: 16px;
  line-height: 2rem;
  appearance: none;
  outline-offset: var(--fds-outline-offset);
  box-shadow: var(--shadow);
  will-change: box-shadow;
  transition: box-shadow var(--fmc-transition-duration) var(--fmc-transition-timing), background-color var(--fmc-transition-duration) var(--fmc-transition-timing);

  // set focus (instead of focus-visible) to align browser behavior
  // and enhance accessibility by always showing focus on elements
  // that receive keyboard input
  &:focus {
    --shadow: var(--shadow-hover);
    --background-color: var(--background-color-focus);
    --border-bottom-weight: var(--border-bottom-weight-focus);
    --border-bottom: var(--border-bottom-focus);
    outline: var(--fds-outline); // necessary specificity to override global :focus
  }

  &::placeholder {
    color: var(--placeholder-text-color);
    letter-spacing: var(--placeholder-letter-spacing);
  }

  @include fds-set-disabled {
    --background-color: var(--fds-color--disabled3);
    --text-color: var(--text-color-disabled);
    --placeholder-text-color: var(--text-color-disabled);
  }
}

%fmc-input--on-dark {
  --shadow-hover: none;
  --text-color: var(--text-color-on-dark);
  --border-color: var(--border-color-on-dark);
  --placeholder-text-color: var(--placeholder-text-color-on-dark);
  color-scheme: dark;

  // :not(:placeholder-shown) works for a regular text input, so that when the
  // input field is "filled" it shows up with similar style as when it's "focused".
  // But it does not work so well for [type=date] and [type=time] since these
  // fields don't actually have a placeholder. It might be possible to fix this
  // with JavaScript (for instance with setting it initially as [type=text] and do
  // something like `onfocus="(this.type='date')"`)
  &:focus,
  &:not(:placeholder-shown) {
    --text-color: var(--text-color-on-dark-focus);
    --border-color: var(--border-color-on-dark-focus);
    --placeholder-text-color: var(--placeholder-text-color-on-dark-focus);
    --background-color: var(--background-color-focus);
    color-scheme: light;
  }
}

%fmc-focus-wrapper:focus-within {
  outline: var(--fds-outline);
  outline-offset: var(--fds-outline-offset);

  * {
    outline: none;
  }
}
