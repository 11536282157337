[class^='fds-icon'],
[class*='fds-icon'] {
  @extend %fds-icons;
}

.fds-icon--24 {
  font-size: fds-rem(24px);
}

.fds-icon--32 {
  font-size: fds-rem(32px);
}

.fds-icon--48 {
  font-size: fds-rem(48px);
}

.fds-icon--64 {
  font-size: fds-rem(64px);
}

.fds-icon--offset-left {
  --offset: 1.6rem;

  @include fds-set-brand(lincoln) {
    --offset: 0.5rem;
  }

  margin-right: var(--offset);
}

.fds-icon--offset-right {
  --offset: 1.6rem;

  @include fds-set-brand(lincoln) {
    --offset: 0.5rem;
  }

  margin-left: var(--offset);
}

.fds-icon--offset-left-xs {
  margin-right: 0.4rem;
}

.fds-icon--offset-right-xs {
  margin-left: 0.4rem;
}

.fds-icon--offset-left-sm {
  margin-right: 0.8rem;
}

.fds-icon--offset-right-sm {
  margin-left: 0.8rem;
}

.fds-icon--offset-left-lg {
  margin-right: 2.4rem;
}

.fds-icon--offset-right-lg {
  margin-left: 2.4rem;
}

.fds-font--color__primary {
  color: var(--fds-color--primary);
}

.fds-font--color__error {
  color: var(--fds-color--error1);
}

.fds-font--color__success {
  color: var(--fds-color--success1);
}

.fds-font--color__gray {
  color: var(--fds-color--gray3);
}

.fds-font--ford-icons__play-symbol {
  &:before {
    content: $fds-font--ford-icons__play-symbol; 
  }
}

.fds-font--ford-icons__info-filled {
  &:before {
    content: $fds-font--ford-icons__info-filled; 
  }
}

.fds-font--ford-icons__chevron-filled-left {
  &:before {
    content: $fds-font--ford-icons__chevron-filled-left; 
  }
}

.fds-font--ford-icons__success {
  &:before {
    content: $fds-font--ford-icons__success; 
  }
}

.fds-font--ford-icons__long-arrow-back {
  &:before {
    content: $fds-font--ford-icons__long-arrow-back; 
  }
}

.fds-font--ford-icons__info {
  &:before {
    content: $fds-font--ford-icons__info; 
  }
}

.fds-font--ford-icons__chevron-down {
  &:before {
    content: $fds-font--ford-icons__chevron-down; 
  }
}

.fds-font--ford-icons__chevron-up {
  &:before {
    content: $fds-font--ford-icons__chevron-up; 
  }
}

.fds-font--ford-icons__mask {
  &:before {
    content: $fds-font--ford-icons__mask; 
  }
}

.fds-font--ford-icons__clear {
  &:before {
    content: $fds-font--ford-icons__clear; 
  }
}

.fds-font--ford-icons__unmask {
  &:before {
    content: $fds-font--ford-icons__unmask; 
  }
}

.fds-font--ford-icons__warning-outline {
  &:before {
    content: $fds-font--ford-icons__warning-outline; 
  }
}

.fds-font--ford-icons__chevron-left {
  &:before {
    content: $fds-font--ford-icons__chevron-left; 
  }
}

.fds-font--ford-icons__chevron-right {
  &:before {
    content: $fds-font--ford-icons__chevron-right; 
  }
}

.fds-font--ford-icons__edit-outline {
  &:before {
    content: $fds-font--ford-icons__edit-outline; 
  }
}

.fds-font--ford-icons__check-filled {
  &:before {
    content: $fds-font--ford-icons__check-filled; 
  }
}

.fds-font--ford-icons__car-rear-filled {
  &:before {
    content: $fds-font--ford-icons__car-rear-filled; 
  }
}

.fds-font--ford-icons__car-side-filled {
  &:before {
    content: $fds-font--ford-icons__car-side-filled; 
  }
}

.fds-font--ford-icons__seat-filled {
  &:before {
    content: $fds-font--ford-icons__seat-filled; 
  }
}

.fds-font--ford-icons__search {
  &:before {
    content: $fds-font--ford-icons__search; 
  }
}

.fds-font--ford-icons__communication {
  &:before {
    content: $fds-font--ford-icons__communication; 
  }
}

.fds-font--ford-icons__ellipsis {
  &:before {
    content: $fds-font--ford-icons__ellipsis; 
  }
}

.fds-font--ford-icons__minus {
  &:before {
    content: $fds-font--ford-icons__minus; 
  }
}

.fds-font--ford-icons__plus {
  &:before {
    content: $fds-font--ford-icons__plus; 
  }
}

.fds-font--ford-icons__chevron-filled-right {
  &:before {
    content: $fds-font--ford-icons__chevron-filled-right; 
  }
}

.fds-font--ford-icons__tick {
  &:before {
    content: $fds-font--ford-icons__tick; 
  }
}

.fds-font--ford-icons__warning-filled {
  &:before {
    content: $fds-font--ford-icons__warning-filled; 
  }
}

.fds-font--ford-icons__filter {
  &:before {
    content: $fds-font--ford-icons__filter; 
  }
}

.fds-font--ford-icons__location {
  &:before {
    content: $fds-font--ford-icons__location; 
  }
}

.fds-font--ford-icons__circle-clock {
  &:before {
    content: $fds-font--ford-icons__circle-clock; 
  }
}

.fds-font--ford-icons__calendar-filled {
  &:before {
    content: $fds-font--ford-icons__calendar-filled; 
  }
}

.fds-font--ford-icons__circle-times-filled {
  &:before {
    content: $fds-font--ford-icons__circle-times-filled; 
  }
}

.fds-font--ford-icons__delete {
  &:before {
    content: $fds-font--ford-icons__delete; 
  }
}

.fds-font--ford-icons__disabled {
  &:before {
    content: $fds-font--ford-icons__disabled; 
  }
}

.fds-font--ford-icons__business-plan {
  &:before {
    content: $fds-font--ford-icons__business-plan; 
  }
}

.fds-font--ford-icons__edit-filled {
  &:before {
    content: $fds-font--ford-icons__edit-filled; 
  }
}

.fds-font--ford-icons__email {
  &:before {
    content: $fds-font--ford-icons__email; 
  }
}

.fds-font--ford-icons__settings-cog {
  &:before {
    content: $fds-font--ford-icons__settings-cog; 
  }
}

.fds-font--ford-icons__exception {
  &:before {
    content: $fds-font--ford-icons__exception; 
  }
}

.fds-font--ford-icons__notification {
  &:before {
    content: $fds-font--ford-icons__notification; 
  }
}

.fds-font--ford-icons__open {
  &:before {
    content: $fds-font--ford-icons__open; 
  }
}

.fds-font--ford-icons__refresh {
  &:before {
    content: $fds-font--ford-icons__refresh; 
  }
}

.fds-font--ford-icons__settings {
  &:before {
    content: $fds-font--ford-icons__settings; 
  }
}

.fds-font--ford-icons__file {
  &:before {
    content: $fds-font--ford-icons__file; 
  }
}

.fds-font--ford-icons__avatar {
  &:before {
    content: $fds-font--ford-icons__avatar; 
  }
}

.fds-font--ford-icons__document {
  &:before {
    content: $fds-font--ford-icons__document; 
  }
}

.fds-font--ford-icons__first-aid {
  &:before {
    content: $fds-font--ford-icons__first-aid; 
  }
}

.fds-font--ford-icons__secured-lock {
  &:before {
    content: $fds-font--ford-icons__secured-lock; 
  }
}

