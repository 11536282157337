.fmc-badge {
  --label-font-size: #{fds-rem(12px)};
  --badge-padding: #{fds-rem(8px)};
  --badge-width: fit-content;
  --badge-border-radius-all: #{fds-rem(3px)};
  --icon-border-radius-all: 50%;
  --icon-width: #{fds-rem(16px)};
  --icon-height: #{fds-rem(16px)};
  --icon-overflow: hidden;
  --icon-bg-color: inherit;
  --icon-text-color: inherit;

  padding: var(--badge-padding);
  border-radius: var(--badge-border-radius-all);
  width: var(--badge-width);
  font-size: var(--label-font-size);
  display: inline-flex;
  align-items: center;
  gap: #{fds-rem(4px)};

  .fmc-badge--icon {
    width: var(--icon-width);
    height: var(--icon-height);
    overflow: hidden;
  }
}
