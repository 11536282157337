.fmc-side-tabs {
  --tablist-margin: 0 auto;
  --tablist-wrapper-width: #{fds-rem(400px)};
  --tab-list-border-color: transparent;
  --text-size: var(--fmc-type-size);
  --text-weight: normal;
  --text-stretch: condensed;
  --text-color-hover: var(--fds-color--secondary);
  --line-height: #{fds-rem(1.8)};
  --tab-padding: #{fds-rem(32px)};
  --tab-vertical-padding: #{fds-rem(8px) 0};
  --tab-border-color: var(--fds-color--primary);
  --tab-border-bottom-offset: #{fds-rem(16px)};
  --tab-hover-border-height: 1px;
  // --tab-selected-border-height: 3px;
  // --tab-selected-border-radius: var(--tab-selected-border-height);
  // --tab-selected-border-display: none;
  // --tab-selected-border-width: auto;
  // --tab-selected-border-transform: translateX(0);
  // --tab-list-border-height: 1px;
  // --tab-list-border-transform: translateY(calc(var(--tab-list-border-height) - var(--tab-selected-border-height)));
  --outline-offset: #{fds-rem(8px)};

  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  margin: 0 auto;
  flex-flow: row;

  @include fds-set-brand(lincoln) {
    --tab-list-border-color: var(--fds-color--disabled2);
    --text-size: #{fds-rem(16px)};
    --text-weight: var(--fmc-type__weight-medium);
    --text-stretch: normal;
    --text-color-hover: var(--fds-color--primary);
    --tab-border-color: var(--fds-color--secondary);
    // --tab-selected-border-height: 2px;
    // --tab-selected-border-radius: 0;
    // --tab-hover-border-height: 2px;
    // --tab-border-bottom-offset: #{fds-rem(30px)};
    --tab-padding: #{fds-rem(30px) fds-rem(20px) fds-rem(50px)};
    --outline-offset: 1.5rem;
  }
}

.fmc-side-tabs__tablist-wrapper {
  max-width: var(--tablist-wrapper-width);
  padding: var(--tab-vertical-padding);
  flex: 1 1 15%;
  display: flex;
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
  transform: none;
  overflow-y: auto;
  scrollbar-width: none;
  transition: flex var(--fmc-transition-duration) var(--fmc-transition-timing);
  @include media('>fds-md', '<=fds-lg') {
    flex: 1 1 20%;
  }

  @include media('<=fds-md') {
    flex: 1 1 30%;
    &:hover {
      flex: 1 1 50%;
    }
  }
}

.fmc-side-tabs__tablist-wrapper::-webkit-scrollbar {
  display: none;
}

.fmc-side-tabs__tablist {
  display: flex;
  flex-flow: column;
  position: relative;
  padding: var(--tab-vertical-padding);
  margin: var(--tablist-margin);
  width: 100%;
  height: auto;
}

.fmc-side-tabs__tab {
  overflow: visible;
  position: relative;
  cursor: pointer;
  
  font-size: var(--text-size);
  font-stretch: var(--text-stretch);
  font-weight: var(--text-weight);
  line-height: var(--line-height);
  white-space: normal;
  color: var(--fds-color--primary);
  text-align: left;
  padding: var(--tab-padding);

  // remove flash on iOS mobile as accessibility indicators are customized in this CSS
  -webkit-tap-highlight-color: var(--fds-color--tertiary-0);

  & > * {
    pointer-events: none;
  }

  &:hover {
    color: var(--text-color-hover);
  }

  &[aria-selected='true'] {
    background-color: var(--fds-color--gray1);
  }
  
  &:hover:not([aria-selected='true']) {
    color: var(--fds-color--white);
    background-color: var(--fds-color--primary);
    transition: background-color var(--fmc-transition-duration) var(--fmc-transition-timing);
  }

  &:focus-visible::before {
    content: '';
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    bottom: 0;
    right: 0;
    outline: 1px solid var(--fds-color--primary);
    outline-offset: var(--outline-offset);
    transform: translateY(-50%);
  }
}

.fmc-side-tabs__tabpanels {
  position: relative;
  display: flex;
  margin: 1px;
  flex: 1 1 85%;
  width: auto;
  overflow: hidden;
  transform: none;
  overflow-y: auto;
  scrollbar-width: none;
    @include media('>fds-md', '<=fds-lg') {
    flex: 1 1 80%;
  }
  @include media('<=fds-md') {
    flex: 1 1 70%;
  }

}

.fmc-side-tabs__tabpanels::-webkit-scrollbar {
  display: none;
}

.fmc-side-tabs__tabpanel {
  position: relative;
  padding-right: 0;
  display: none;

  &.fmc-side-tabs__tabpanel--focus {
    outline: 1px solid var(--fds-color--primary);
  }

  &.fmc-side-tabs__tabpanel--active {
    display: block;
  }
}





