.fmc-filter-chips {
  --icon-gap: #{fds-rem(0.8rem)};
  --dot-size: #{fds-rem(2.4rem)};
  --border: #{fds-rem(0.1rem)} solid var(--fds-color--primary);
  --border-radius: 0.3rem;
  --font-size: #{fds-rem(2.4rem)};
  --padding: 0 1.6rem;
  --border-hover: #{fds-rem(0.1rem)} solid var(--fds-color--secondary);
  --border-active: #{fds-rem(0.1rem)} solid var(--fds-color--secondary);
  --background-color-hover: var(--fds-color--primary-5);
  --background-color-active: transparent;
  --color-disabled: var(--fds-color--disabled3);
  --border-disabled: #{fds-rem(1px)} solid var(--fds-color--disabled3);
  --font-weight: inherit;
  --cursor: default;

  display: flex;
  gap: #{fds-rem(2rem)};
  flex-wrap: wrap;

  > * {
    display: flex;
  }

  &:focus-visible {
    outline: var(--fds-outline);
    outline-offset: #{fds-rem(0.8rem)};
  }

  @include fds-set-brand(lincoln) {
    --border-radius: 0;
    --border: #{fds-rem(0.1rem)} solid var(--fds-color--disabled2);
    --font-size: #{fds-rem(1.8rem)};
    --padding: #{fds-rem(0 0.3rem 0 0.7rem)};
    --dot-size: #{fds-rem(3.6rem)};
    --border-hover: #{fds-rem(0.25rem)} solid var(--fds-color--disabled3);
    --border-active: #{fds-rem(0.25rem)} solid var(--fds-color--disabled3);
    --background-color-hover: transparent;
    --background-color-active: transparent;
    --color-disabled: var(--fds-color--disabled1);
    --border-disabled: #{fds-rem(0.1rem)} solid var(--fds-color--disabled2);
    --font-weight: 500;
    --cursor: pointer;

    button {
      font-size: #{fds-rem(1.9rem)};
      margin-left: #{fds-rem(0.2rem)};
      font-weight: 800;
    }

    .showMoreChipsBtn {
      display: flex;
      align-items: center;
    }

    .clearAllIcon {
      font-size: #{fds-rem(1.9rem)};
      margin-left: #{fds-rem(0.2rem)};
      width: #{fds-rem(10px)};
      height: #{fds-rem(10px)};
      display: flex;
      align-items: center;
      cursor: var(--cursor);
      font-weight: 800;
    }
  }

  .fmc-filter-chips__dot {
    display: inline-block;
    width: var(--dot-size);
    height: var(--dot-size);
    margin-right: var(--icon-gap);
    border-radius: 50%;
  }

  .fmc-filter-chips__chip {
    display: flex;
    align-items: center;
    color: var(--fds-color--primary);
    font-size: var(--font-size);
    font-stretch: condensed;
    height: #{fds-rem(4rem)};
    padding: var(--padding);
    border-radius: var(--border-radius);
    border: var(--border);
    font-weight: var(--font-weight);
    cursor: var(--cursor);

    &:hover:not(.fmc-filter-chips__chip--disabled) {
      border: var(--border-hover);
      background-color: var(--background-color-hover);
    }

    &:active:not(.fmc-filter-chips__chip--disabled) {
      border: var(--border-active);
      background-color: var(background-color-active);
    }
  }

  .fmc-filter-chips__chip--disabled {
    color: var(--color-disabled);
    border: var(--border-disabled);
    cursor: not-allowed;

    & * {
      cursor: not-allowed;
    }
  }

  &.fmc-filter-chips--scrollable {
    overflow-x: auto;
    flex-wrap: nowrap;
    padding: #{fds-rem(1rem)};
  }

  &.fmc-filter-chips--scrollable .fmc-filter-chips__chip {
    flex: 0 0 auto;
  }

  .fds-font--ford-icons__clear:focus-visible {
    outline: var(--fds-outline);
    outline-offset: #{fds-rem(0.8rem)};
  }
}
