.fmc-tracker {
  --dot-size-standard: 5.5rem;
  --dot-size-simple: 2.8rem;
  --dot-size: var(--dot-size-standard);
  --dot-outline-color: var(--fds-color--gray2);
  --dot-outline-color-active: transparent;
  --dot-highlight-color: transparent;
  --dot-highlight-offset-standard: 0.5rem;
  --dot-highlight-offset-simple: 0.4rem;
  --dot-highlight-offset: var(--dot-highlight-offset-standard);
  --dot-text-color: var(--fds-color--gray3);
  --dot-text-color-active: var(--fds-color--white);
  --dot-color-success: var(--fds-color--success1);
  --dot-color-active: var(--fds-color--primary);
  --cursor: pointer;
  --shadow: none;
  --line-weight: 0.1rem;
  --line-weight-complete: 0.3rem;
  --line-color: var(--fds-color--gray2);
  --line-color-complete: var(--fds-color--primary);
  --line-animation-width: 0%;
  --font-weight-active: 400;
  --font-weight-future: 400;
  --title-padding: 0 1rem;
  --title-text-transform: none;
  --title-font-size: inherit;
  --title-letter-spacing: inherit;
  --title-cursor: default;
  --title-border-bottom: none;
  --title-border-bottom-hover: none;
  --title-width: auto;
  --title-margin: inherit;
  --title-color: var(--fds-color--primary);
  --dot-font-size: 1.2rem;
  --dot-after-width-standard: unset;
  --dot-after-height-standard: unset;
  --dot-after-width-simple: unset;
  --dot-after-height-simple: unset;
  --dot-after-width: var(--dot-after-width-standard);
  --dot-after-height: var(--dot-after-height-standard);

  @include fds-set-brand(lincoln) {
    --dot-outline-color: var(--fds-color--gray4);
    --dot-color-success: var(--fds-color--secondary);
    --dot-color-active: transparent;
    --dot-outline-color-active: var(--fds-color--secondary);
    --dot-text-color-active: var(--fds-color--gray3);
    --line-color: var(--fds-color--disabled2);
    --line-color-complete: var(--fds-color--disabled2);
    --line-weight-complete: 0.1rem;
    --font-weight-active: 800;
    --title-padding: 0;
    --title-text-transform: uppercase;
    --title-font-size: 1.2rem;
    --title-letter-spacing: 0.15rem;
    --title-cursor: pointer;
    --title-border-bottom: 1px solid transparent;
    --title-width: fit-content;
    --title-margin: 0 auto;
    --dot-font-size: 1.1rem;
    --title-border-bottom-hover: 1px solid var(--fds-color--secondary);
    --dot-size-standard: 7.5rem;
    --dot-size-simple: 3.8rem;
    --dot-size: var(--dot-size-standard);
    --dot-highlight-offset-standard: unset;
    --dot-highlight-offset-simple: unset;
    --dot-highlight-offset: var(--dot-highlight-offset-standard);
    --dot-after-width-standard: 65%;
    --dot-after-height-standard: 65%;
    --dot-after-width-simple: 55%;
    --dot-after-height-simple: 55%;
    --dot-after-width: var(--dot-after-width-standard);
    --dot-after-height: var(--dot-after-height-standard);
  }

  display: flex;
  justify-content: center;
  align-items: flex-start;
  list-style-type: none;

  &.fmc-tracker--simple {
    --dot-size: var(--dot-size-simple);
    --dot-highlight-offset: var(--dot-highlight-offset-simple);
    --dot-after-width: var(--dot-after-width-simple);
    --dot-after-height: var(--dot-after-height-simple);
  }

  .fmc-tracker__step {
    position: relative;
    width: 100%;
    min-width: calc(var(--dot-size) + 10rem);
    max-width: calc(var(--dot-size) + 20rem);

    &::before,
    &::after {
      display: block;
      content: '';
      position: absolute;
      left: 50%;
      right: 0;
      bottom: 0;
      width: 100%;
    }

    &::before {
      top: calc((var(--dot-size) - var(--line-weight)) / 2);
      height: var(--line-weight);
      background-color: var(--line-color);
    }

    &::after {
      width: 100%;
      transform: scaleX(var(--line-animation-width));
      transform-origin: left center;
      top: calc((var(--dot-size) - var(--line-weight-complete)) / 2);
      height: var(--line-weight-complete);
      background-color: var(--line-color-complete);
      transition: transform var(--fmc-transition-duration) var(--fmc-transition-timing);
    }

    &:last-child {
      &::before,
      &::after {
        display: none;
      }
    }
  }

  .fmc-tracker__step--complete {
    --line-animation-width: 100%;
  }

  .fmc-tracker__dot {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: var(--dot-size);
    height: var(--dot-size);
    background-color: var(--fds-color--white);
    color: var(--dot-text-color);
    border-radius: 100%;
    z-index: 1;
    transition: color var(--fmc-transition-duration) var(--fmc-transition-timing);
    cursor: var(--cursor);
    font-size: var(--dot-font-size);
    margin: 0 auto;
    font-weight: var(--font-weight-active);

    &:focus-visible {
      outline: var(--fds-outline);
      outline-offset: 0.5rem;
    }

    &::after {
      content: '';
      position: absolute;
      top: var(--dot-highlight-offset);
      left: var(--dot-highlight-offset);
      right: var(--dot-highlight-offset);
      bottom: var(--dot-highlight-offset);
      border: 1px solid var(--dot-outline-color);
      background-color: var(--dot-highlight-color);
      border-radius: 100%;
      box-shadow: var(--shadow);
      z-index: -1;
      transition: box-shadow var(--fmc-transition-duration) var(--fmc-transition-timing),
        background-color var(--fmc-transition-duration) var(--fmc-transition-timing),
        border-color var(--fmc-transition-duration) var(--fmc-transition-timing);
      width: var(--dot-after-width);
      height: var(--dot-after-height);
    }

    @include fds-set-hover {
      --dot-highlight-color: var(--fds-color--secondary);
      --dot-text-color: var(--fds-color--white);
      --shadow: #{$fds-elevation__box-shadow--layer2};
      --dot-outline-color: transparent;
    }

    @include fds-set-disabled {
      --dot-highlight-color: var(--fds-color--disabled3);
      --dot-text-color: var(--fds-color--white);
      --dot-outline-color: transparent;
      --cursor: not-allowed;
      --shadow: none;
    }
  }

  .fmc-tracker__dot--active {
    --dot-highlight-color: var(--dot-color-active);
    --dot-text-color: var(--dot-text-color-active);
    --dot-outline-color: var(--dot-outline-color-active);
  }

  .fmc-tracker__dot--success {
    --dot-highlight-color: var(--dot-color-success);
    --dot-text-color: var(--fds-color--white);
    --dot-outline-color: transparent;
  }

  .fmc-tracker__title {
    text-align: center;
    overflow-wrap: break-word;
    position: relative;
    font-weight: var(--font-weight-active);
    padding: var(--title-padding);
    text-transform: var(--title-text-transform);
    font-size: var(--title-font-size);
    letter-spacing: var(--title-letter-spacing);
    cursor: var(--title-cursor);
    border-bottom: var(--title-border-bottom);
    width: var(--title-width);
    margin: var(--title-margin);
    transition: border-bottom var(--fmc-transition-duration) var(--fmc-transition-timing);
    color: var(--title-color);

    &:hover {
      border-bottom: var(--title-border-bottom-hover);
    }
  }

  .fmc-tracker__title--disabled {
    cursor: not-allowed;

    &:hover {
      border-bottom: var(--title-border-bottom);
    }
  }

  .fmc-tracker__title--future {
    @include fds-set-brand(lincoln) {
      --title-color: var(--fds-color--gray4);
      font-weight: var(--font-weight-future);
    }
  }
}
