/*! name: @uvarov.frontend/vanilla-calendar | url: https://github.com/uvarov-frontend/vanilla-calendar */
$fmc-calendar-picker-calendar-day__btn--height: 3.25rem;
$fmc-calendar-picker-calendar-day__btn--align-items: flex-start;
$fmc-calendar-picker-calendar-day__btn--justify-content: flex-end;
$fmc-calendar-picker-calendar-day-row-gap: 0;

.fmc-calendar-picker-calendar {
    position: relative;
    display: flex;
    min-width: 350px;
    max-height: 300px;
    flex-direction: column;
    border-radius: 0.25rem;
    padding: 1rem;
    border: 1px solid $fds-color--gray2;
    font-family: $fds-font--antenna;
}

.fmc-calendar-picker-calendar button:focus-visible {
    border-radius: 0.5rem;
    outline-style: solid;
    outline-width: 1px;
}
.fmc-calendar-picker-calendar.fmc-calendar-picker-calendar_multiple .fmc-calendar-picker-calendar-month,
.fmc-calendar-picker-calendar.fmc-calendar-picker-calendar_multiple .fmc-calendar-picker-calendar-year {
    cursor: default;
}
.fmc-calendar-picker-calendar.fmc-calendar-picker-calendar_multiple .fmc-calendar-picker-calendar-month.fmc-calendar-picker-calendar-month_disabled,
.fmc-calendar-picker-calendar.fmc-calendar-picker-calendar_multiple .fmc-calendar-picker-calendar-year.fmc-calendar-picker-calendar-year_disabled {
    pointer-events: auto;
}
.fmc-calendar-picker-calendar.fmc-calendar-picker-calendar_multiple .fmc-calendar-picker-calendar-days {
    flex-grow: 0;
}
.fmc-calendar-picker-calendar-controls {
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 20;
    box-sizing: content-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1.25rem;
}
.fmc-calendar-picker-calendar-arrow {
    pointer-events: auto;
    position: relative;
    display: block;
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
    border-width: 0px;
}
.fmc-calendar-picker-calendar-arrow:before {
    position: absolute;
    inset: 0;
    content: var(--tw-content);
    content: "";
    -webkit-mask-image: url('data:image/svg+xml, %3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"%3E%3Cpath d="M12 16c-.3 0-.5-.1-.7-.3l-6-6c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l5.3 5.3 5.3-5.3c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-6 6c-.2.2-.4.3-.7.3z"/%3E%3C/svg%3E');
    mask-image: url('data:image/svg+xml, %3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"%3E%3Cpath d="M12 16c-.3 0-.5-.1-.7-.3l-6-6c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l5.3 5.3 5.3-5.3c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-6 6c-.2.2-.4.3-.7.3z"/%3E%3C/svg%3E');
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: 50% 50%;
    mask-position: 50% 50%;
}
.fmc-calendar-picker-calendar-arrow.fmc-calendar-picker-calendar-arrow_prev:before {
    -webkit-transform: rotateZ(90deg);
    transform: rotate(90deg);
}
.fmc-calendar-picker-calendar-arrow.fmc-calendar-picker-calendar-arrow_next:before {
    -webkit-transform: rotateZ(-90deg);
    transform: rotate(-90deg);
}
.fmc-calendar-picker-calendar-grid {
    display: grid;
    flex-grow: 1;
    grid-auto-flow: column;
    gap: 1.75rem;
}
.fmc-calendar-picker-calendar-column {
    display: flex;
    min-width: -webkit-min-content;
    min-width: min-content;
    flex-direction: column;
}
.fmc-calendar-picker-calendar-header {
    position: relative;
    margin-bottom: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}
.fmc-calendar-picker-calendar-header__content {
    display: grid;
    flex: 0 0 auto;
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content;
    grid-auto-flow: column;
    align-items: center;
    justify-content: center;
    white-space: pre-wrap;
    padding-left: 1rem;
    padding-right: 1rem;
}
.fmc-calendar-picker-calendar-month,
.fmc-calendar-picker-calendar-year {
    cursor: pointer;
    border-radius: 0.25rem;
    border-width: 0px;
    background-color: transparent;
    padding: 0.25rem;
    padding-bottom: .75rem;
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: 700;
}
.fmc-calendar-picker-calendar-month.fmc-calendar-picker-calendar-month_disabled,
.fmc-calendar-picker-calendar-year.fmc-calendar-picker-calendar-year_disabled {
    pointer-events: none;
}
.fmc-calendar-picker-calendar-months {
    display: grid;
    flex-grow: 1;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    align-items: center;
    row-gap: 1rem;
    -webkit-column-gap: 0.5rem;
    column-gap: 0.5rem;
}
.fmc-calendar-picker-calendar-years {
    display: grid;
    flex-grow: 1;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    align-items: center;
    row-gap: 1rem;
    -webkit-column-gap: 0.5rem;
    column-gap: 0.5rem;
}
.fmc-calendar-picker-calendar-months_selecting .fmc-calendar-picker-calendar-months__month,
.fmc-calendar-picker-calendar-years_selecting .fmc-calendar-picker-calendar-years__year {
    cursor: pointer;
}
.fmc-calendar-picker-calendar-months_selecting .fmc-calendar-picker-calendar-months__month .fmc-calendar-picker-calendar-months__month_disabled,
.fmc-calendar-picker-calendar-years_selecting .fmc-calendar-picker-calendar-years__year .fmc-calendar-picker-calendar-years__year_disabled {
    cursor: default;
}
.fmc-calendar-picker-calendar-months__month,
.fmc-calendar-picker-calendar-years__year {
    display: flex;
    height: 2.5rem;
    align-items: center;
    justify-content: center;
    word-break: break-all;
    border-radius: 0.5rem;
    border-width: 0px;
    padding: 0.25rem;
    text-align: center;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 600;
}
.fmc-calendar-picker-calendar-months__month.fmc-calendar-picker-calendar-months__month_disabled,
.fmc-calendar-picker-calendar-years__year.fmc-calendar-picker-calendar-years__year_disabled {
    pointer-events: none;
}
.fmc-calendar-picker-calendar-week-numbers {
    display: flex;
    flex-direction: column;
}
.fmc-calendar-picker-calendar-week-numbers__title {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 700;
}
.fmc-calendar-picker-calendar-week-numbers__content {
    display: grid;
    flex-grow: 1;
    grid-auto-flow: row;
    align-items: center;
    justify-items: center;
    row-gap: 0.25rem;
}
.fmc-calendar-picker-calendar-week-number {
    display: flex;
    height: 100%;
    min-height: 1.875rem;
    width: 100%;
    min-width: 1.875rem;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 600;
}
.fmc-calendar-picker-calendar-wrapper {
    display: flex;
    flex-grow: 1;
}
.fmc-calendar-picker-calendar-content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}
.fmc-calendar-picker-calendar-week {
    margin-bottom: 0.5rem;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    justify-items: center;
}
.fmc-calendar-picker-calendar-week__day {
    display: flex;
    height: 100%;
    width: 100%;
    min-width: $fmc-calendar-picker-calendar-day__btn--height;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    line-height: 1rem;
    font-weight: 700;
}
.fmc-calendar-picker-calendar-days {
    display: grid;
    flex-grow: 1;
    grid-template-columns: repeat(7, 1fr);
    align-items: center;
    justify-items: center;
    row-gap: $fmc-calendar-picker-calendar-day-row-gap;
}

// customization of days
.fmc-calendar-picker-calendar-days {
  .fmc-calendar-picker-calendar-day__btn {
    border: 1px solid $fds-color--gray1;
    position: relative;
    &[class*='selected-'] {
      &:after {
        content: '';
        position: absolute;
        left: .6rem;
        bottom: .6rem;
        height: calc(1rem + 2px);
        width: calc(1rem + 2px);
        border: 2px solid $fds-color--white;
        border-radius: calc(1rem + 2px);
      }
    }
    &.selected-secondary {
      &:after {
        background-color: $fds-color--secondary;
      }
    }
    &.selected-primary {
      &:after {
        background-color: $fds-color--primary;
      }
    }
    &.selected-success1 {
      &:after {
        background-color: $fds-color--success1;
      }
    }
    &.selected-caution2 {
      &:after {
        background-color: $fds-color--caution2;
      }
    }
  }
}

.fmc-calendar-picker__date-details {
  .fmc-calendar-picker__date-detail-item {
    position: relative;
    z-index: 0;
    padding-left: 2rem;
    &[class*='selected-'] {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: .5rem;
        height: 1.25rem;
        width: 1.25rem;
        border-radius: 1.25rem;
        z-index: 1;
      }
    }
    &.selected-secondary {
      &:after {
        background-color: $fds-color--secondary;
      }
    }
    &.selected-primary {
      &:after {
        background-color: $fds-color--primary;
      }
    }
    &.selected-success1 {
      &:after {
        background-color: $fds-color--success1;
      }
    }
    &.selected-caution2 {
      &:after {
        background-color: $fds-color--caution2;
      }
    }
  }

  .fmc-calendar-picker__date-detail {
    .fmc-calendar-picker__date-detail-description {
      opacity: 0;
      height: 0;
    }
  }
  .fmc-calendar-picker__date-detail--selected {
    background-color: $fds-color--gray1;
    .fmc-calendar-picker__date-detail-description {
      opacity: 1;
      height: auto;
    }
  }
}

.fmc-calendar-picker-calendar-days_selecting .fmc-calendar-picker-calendar-day__btn {
    cursor: pointer;
}
.fmc-calendar-picker-calendar-days_selecting .fmc-calendar-picker-calendar-day__btn_disabled {
    cursor: default;
}
.fmc-calendar-picker-calendar-day {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.fmc-calendar-picker-calendar-day:focus-visible .fmc-calendar-picker-calendar-day__popup,
.fmc-calendar-picker-calendar-day:hover .fmc-calendar-picker-calendar-day__popup {
    pointer-events: auto;
    opacity: 1;
}
.fmc-calendar-picker-calendar-day_selected-first .fmc-calendar-picker-calendar-day__btn_selected {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}
.fmc-calendar-picker-calendar-day_selected-last .fmc-calendar-picker-calendar-day__btn_selected {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}
.fmc-calendar-picker-calendar-day_selected-intermediate .fmc-calendar-picker-calendar-day__btn_intermediate {
    border-radius: 0;
}
.fmc-calendar-picker-calendar-day__btn {
    display: flex;
    height: 100%;
    min-height: $fmc-calendar-picker-calendar-day__btn--height;
    width: 100%;
    min-width: $fmc-calendar-picker-calendar-day__btn--height;
    cursor: default;
    align-items: $fmc-calendar-picker-calendar-day__btn--align-items;
    justify-content: $fmc-calendar-picker-calendar-day__btn--justify-content;
    border-width: 0px;
    font-size: .85rem;
    line-height: 1rem;
    font-weight: 400;
    padding: .35rem;
}
.fmc-calendar-picker-calendar-day__btn_today {
    font-weight: 600;
}
.fmc-calendar-picker-calendar-day__btn_disabled {
    pointer-events: none;
}
.fmc-calendar-picker-calendar-day__popup {
    -webkit-transform: translateX(-50%);
    transform: translate(-50%);
    pointer-events: none;
    position: absolute;
    left: 50%;
    top: 100%;
    z-index: 20;
    width: 8rem;
    padding: 1rem 0.75rem 0.5rem;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 400;
    opacity: 0;
}
.fmc-calendar-picker-calendar-day__popup:before {
    content: var(--tw-content);
    position: absolute;
}
.fmc-calendar-picker-calendar-day__popup:after {
    content: var(--tw-content);
    position: absolute;
}
.fmc-calendar-picker-calendar-day__popup:hover {
    pointer-events: auto;
    opacity: 1;
}
.fmc-calendar-picker-calendar-day__popup:before {
    content: "";
    left: 0;
    bottom: 0;
    z-index: -10;
    display: block;
    height: calc(100% - 8px);
    width: 100%;
    border-radius: 0.5rem;
}
.fmc-calendar-picker-calendar-day__popup:after {
    content: "";
    -webkit-transform: translateX(-50%);
    transform: translate(-50%);
    left: 50%;
    top: 1px;
    z-index: -10;
    display: block;
    height: 0px;
    width: 0px;
    border-left-width: 8px;
    border-right-width: 8px;
    border-top-width: 0px;
    border-bottom-width: 8px;
    border-style: solid;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
}
.fmc-calendar-picker-calendar-time {
    margin-top: 0.75rem;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0.75rem;
    border-top-width: 1px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-style: solid;
    padding-top: 0.75rem;
}
.fmc-calendar-picker-calendar-time__content {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
}
.fmc-calendar-picker-calendar-time__hours {
    position: relative;
    margin-right: 0.35rem;
    width: 1.75rem;
}
.fmc-calendar-picker-calendar-time__hours:after {
    position: absolute;
    right: -5px;
    top: 50%;
    margin-top: calc(-50% + 1px);
    display: block;
    --tw-content: ":";
    content: var(--tw-content);
}
.fmc-calendar-picker-calendar-time__minutes {
    width: 1.75rem;
}
.fmc-calendar-picker-calendar-time__hours input,
.fmc-calendar-picker-calendar-time__minutes input {
    position: relative;
    margin: 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
    border-radius: 0.25rem;
    border-width: 0px;
    padding: 0.125rem;
    text-align: center;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.125rem;
}
.fmc-calendar-picker-calendar-time__hours input:focus-visible,
.fmc-calendar-picker-calendar-time__minutes input:focus-visible {
    outline-style: solid;
    outline-width: 1px;
}
.fmc-calendar-picker-calendar-time__hours input:disabled,
.fmc-calendar-picker-calendar-time__minutes input:disabled {
    cursor: default;
}
.fmc-calendar-picker-calendar-time__hours input:hover:disabled,
.fmc-calendar-picker-calendar-time__minutes input:hover:disabled {
    background-color: transparent;
}
.fmc-calendar-picker-calendar-time__keeping {
    margin-left: 1px;
    margin-top: 0.25rem;
    width: 22px;
    cursor: pointer;
    border-radius: 0.25rem;
    font-size: 11px;
}
.fmc-calendar-picker-calendar-time__keeping:focus-visible {
    outline-style: solid;
    outline-width: 1px;
}
.fmc-calendar-picker-calendar-time__keeping:disabled {
    cursor: default;
}
.fmc-calendar-picker-calendar-time__keeping:hover:disabled {
    background-color: transparent;
}
.fmc-calendar-picker-calendar-time__ranges {
    display: grid;
    grid-auto-flow: row;
}
.fmc-calendar-picker-calendar-time__range {
    position: relative;
    z-index: 10;
    font-size: 0;
}
.fmc-calendar-picker-calendar-time__range:before {
    content: var(--tw-content);
    left: 0;
}
.fmc-calendar-picker-calendar-time__range:after {
    content: var(--tw-content);
    right: 0;
}
.fmc-calendar-picker-calendar-time__range:before,
.fmc-calendar-picker-calendar-time__range:after {
    content: "";
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    pointer-events: none;
    position: absolute;
    top: 50%;
    z-index: 10;
    height: 0.5rem;
    width: 1px;
}
.fmc-calendar-picker-calendar-time__range input {
    position: relative;
    margin: 0;
    height: 1.25rem;
    width: 100%;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    outline-width: 0px;
}
.fmc-calendar-picker-calendar-time__range input::-webkit-slider-thumb {
    position: relative;
    z-index: 20;
    margin-top: -0.5rem;
    box-sizing: border-box;
    height: 1rem;
    width: 0.75rem;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    border-width: 1px;
    border-style: solid;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.fmc-calendar-picker-calendar-time__range input::-moz-range-thumb {
    position: relative;
    z-index: 20;
    box-sizing: border-box;
    height: 1rem;
    width: 0.75rem;
    cursor: pointer;
    border-radius: 0.25rem;
    border-width: 1px;
    border-style: solid;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.fmc-calendar-picker-calendar-time__range input::-webkit-slider-runnable-track {
    margin-top: 1px;
    box-sizing: border-box;
    height: 1px;
    width: 100%;
    cursor: pointer;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.fmc-calendar-picker-calendar-time__range input::-moz-range-track {
    margin-top: 1px;
    box-sizing: border-box;
    height: 1px;
    width: 100%;
    cursor: pointer;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
