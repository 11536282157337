%flex-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fmc-pagination {
  @extend %flex-centered;

  --pagination-gap: #{fds-rem(8px)};
  --pagination-item-wh: #{fds-rem(40px)};
  --pagination-prev-next-wh: #{fds-rem(56px)};
  --pagination-prev-next-margin-inner: #{fds-rem(16px)};
  --pagination-link-padding-x: #{fds-rem(8px)};
  --pagination-chevron-padding-inner: #{fds-rem(16px)};
  --pagination-chevron-font-size: #{fds-rem(16px)};
  --pagination-outline-width: 1px;
  --pagination-active-border-width: 0 0 2px 0; /* TRBL */
  --pagination-active-border-radius: 0;
  --pagination-link-color: var(--fds-color--primary);
  --pagination-focus-color: var(--fds-color--secondary);
  --pagination-focus-outline-color: var(--fds-color--primary);
  --pagination-hover-color: var(--pagination-focus-color);
  --pagination-hover-background-color: transparent;
  --pagination-active-color: var(--pagination-link-color);
  --pagination-disabled-color: var(--fds-color--disabled3);
  --pagination-click-target-size: #{fds-rem(44px)};

  @include fds-set-brand(lincoln) {
    --pagination-gap: #{fds-rem(16px)};
    --pagination-item-wh: #{fds-rem(32px)};
    --pagination-prev-next-wh: #{fds-rem(40px)};
    --pagination-prev-next-margin-inner: #{fds-rem(12px)};
    --pagination-active-border-width: 2px;
    --pagination-active-border-radius: var(--pagination-item-wh);
    --pagination-chevron-padding-inner: 0;
    --pagination-chevron-font-size: #{fds-rem(24px)};
    --pagination-disabled-color: var(--fds-color--gray4);
    --pagination-active-color: var(--pagination-disabled-color);
    --pagination-hover-color: var(--fds-color--tertiary);
    --pagination-hover-background-color: var(--fds-color--primary);
  }

  list-style: none;
  margin: 0 auto;
  gap: var(--pagination-gap);

  .fmc-pagination__item {
    position: relative;
    z-index: 0;
  }

  .fmc-pagination__link {
    @extend %flex-centered;

    min-width: var(--pagination-item-wh);
    height: var(--pagination-item-wh);
    padding: 0 var(--pagination-link-padding-x);
    white-space: nowrap;
    border-radius: var(--pagination-active-border-radius);

    &::before {
      content: "";
      position: absolute;
      min-width: var(--pagination-click-target-size, 44px);
      height: var(--pagination-click-target-size, 44px);
      border-radius: var(--pagination-click-target-size, 44px);
      z-index: -1;
    }
  }

  a.fmc-pagination__link:not(.fmc-pagination__active) {
    color: var(--pagination-link-color);

    outline: var(--pagination-outline-width) solid transparent;
    transition: outline-color var(--fmc-transition-duration) linear;

    &:focus-visible {
      outline-color: var(--pagination-focus-outline-color);
      color: var(--pagination-focus-color);
    }

    &:hover {
      color: var(--pagination-hover-color);
      background-color: var(--pagination-hover-background-color);
    }
  }

  .fmc-pagination__active {
    color: var(--pagination-active-color);
    pointer-events: none;
    position: relative;

    &::after {
      position: absolute;
      bottom: 0;
      content: "";
      width: 100%;
      height: 100%;
      border: solid var(--pagination-active-color);
      border-width: var(--pagination-active-border-width); /* must be on its own line */
      border-radius: var(--pagination-active-border-radius);
    }
  }

  .fmc-pagination__prev,
  .fmc-pagination__next {
    @extend %flex-centered;

    min-width: var(--pagination-prev-next-wh);
    height: var(--pagination-prev-next-wh);
    padding: 0 var(--pagination-link-padding-x);
    white-space: nowrap;
    border-radius: var(--pagination-active-border-radius);
    color: var(--pagination-link-color);

    outline: var(--pagination-outline-width) solid transparent;
    transition: outline-color var(--fmc-transition-duration) linear;

    &:focus-visible {
      outline-color: var(--pagination-focus-outline-color);
      color: var(--pagination-focus-color);
    }

    &:hover {
      color: var(--pagination-hover-color);
      background-color: var(--pagination-hover-background-color);
    }

    &::before,
    &::after {
      font-family: var(--fmc-font--ford-icons);
      font-size: var(--pagination-chevron-font-size);
    }

  }

  .fmc-pagination__prev {
    margin-right: var(--pagination-prev-next-margin-inner);

    &::before {
      content: var(--fds-font--ford-icons__chevron-left);
      padding-right: var(--pagination-chevron-padding-inner);
    }
  }

  .fmc-pagination__next {
    margin-left: var(--pagination-prev-next-margin-inner);

    &::after {
      content: var(--fds-font--ford-icons__chevron-right);
      padding-left: var(--pagination-chevron-padding-inner);
    }
  }

  .fmc-pagination__disabled {
    --pagination-link-color: var(--pagination-disabled-color);
    --pagination-hover-color: var(--pagination-disabled-color);

    pointer-events: none;
  }

  @include fds-set-brand(lincoln) {
    a.fmc-pagination__prev,
    a.fmc-pagination__next {
      /* mobile-first would get too complicated here */
      /* since "lincoln mobile" is the odd one out */
      @include media('<fds-sm') {
        --pagination-prev-next-margin-inner: 0;

        color: var(--fds-color--secondary);
        padding: 0;
        border: var(--pagination-active-border-width) solid var(--fds-color--secondary);
        border-radius: var(--pagination-active-border-radius);

        span {
          display: none;
        }

        &:hover {
          color: var(--pagination-hover-color);
          background-color: var(--fds-color--secondary);
        }

        &.fmc-pagination__disabled {
          color: var(--pagination-disabled-color);
          border-color: var(--pagination-disabled-color);
        }
      }

      @include media('>=fds-sm') {
        &:hover {
          color: var(--fds-color--primary);
          background-color: transparent;

          /* stylelint-disable max-nesting-depth */
          span {
            text-decoration: underline var(--fds-color--secondary) solid 2px;
            text-underline-offset: 3px;
          }
          /* stylelint-enable max-nesting-depth */
        }
      }
    }
  }
}
